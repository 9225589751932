import { AxiosRequestConfig } from 'axios';
import { axios } from './instance';

export const getAssetGraphDefinitions = async (requestConfig?: AxiosRequestConfig) => {
  return await axios.get<AssetGraphDefinitionsResponse>(
    `/public/ass/api/v1/risks/assetGraphDefinitions`,
    requestConfig
  );
};

export const getCriticalityInfo = async (
  assetId: string | undefined,
  requestConfig?: AxiosRequestConfig
) => {
  return await axios.get<CriticalityInfoResponse>(`/public/ass/api/v1/asset/criticalityInfo`, {
    ...requestConfig,
    params: {
      assetId, // dynamic assetID from cloud asset API
      assetType: 6, // hardcoded to 6 because we are searching a cloud asset's criticality info
    },
  });
};
export const getAssetProfile = async (
  searchParams: { assetId: string | undefined; assetSubtype: string },
  requestConfig?: AxiosRequestConfig
) => {
  const url = '/public/sase/api/v1/bigtable/forward/v1/cloud-assets/asset-profile-v2';
  return await axios.get<BigTableCloudAssetProfileResponse>(url, {
    ...requestConfig,
    params: searchParams,
  });
};

export const getAssetDetail = async (
  { id }: { id: string | undefined },
  requestConfig?: AxiosRequestConfig
) => {
  const url = `/public/sase/api/v1/bigtable/forward/v1/cloud-assets/id/${id}`;
  return await axios.get<BigTableCloudAssetDetailResponse>(url, requestConfig);
};

export const getRiskHistory = async (
  searchParams: { assetId: string | undefined; assetSubtype: string },
  requestConfig?: AxiosRequestConfig
) => {
  const url = '/public/ass/api/v1/trilogy/attackSurface/internet/riskHistory';
  return await axios.get<RiskHistoryResponse>(url, { ...requestConfig, params: searchParams });
};
