import { useMemo, useState, useRef } from 'react';
import { Text, useTheme, Tooltip, Badge } from '@tonic-ui/react';
import { findRelatedEdgesByGroupId } from './linkHelpers';
import {
  getGraphConstants,
  getNodeRiskIndicatorRadiusByZoomValue,
  getNodeRiskIndicatorOffsetByZoomValue,
  minZoomLevel,
  ZoomLevel,
  graphBGColorCode,
  nodeLabelTextHeight,
} from '../helpers';
import { useCloudAssetsGraphContext } from '../useCloudAssetsGraphContext';
import CloudAssetIcon from '../../CloudAssetIcon';
import { getRiskLevel, getRiskColor, securityGroupServiceTypes } from '../../helpers';
import { pendoTrack } from '../../../utils/pendo';

type CloudAssetsGraphNodeProps = {
  x: number;
  y: number;
  r?: number;
  text: string;
  serviceType: string;
  serviceName?: string;
  riskiestAssetScore?: number;
  totalAssets?: number;
  groupId?: number;
  flipGraphOrientation?: boolean;
  onGroupNodeClick?: (groupID: number | undefined) => void;
  zoomLevel: number;
  isChildren?: boolean;
  parentGroupId?: number;
  securityGroupsCount?: number;
  secondaryLinkList: SvcRisksApi.Schemas.Link[];
  primaryLinkList: SvcRisksApi.Schemas.Link[];
  securityGroupLinkList: SvcRisksApi.Schemas.Link[];
};

const CloudAssetsGraphNode = (props: CloudAssetsGraphNodeProps) => {
  const {
    x,
    y,
    text,
    r = 30,
    serviceType,
    serviceName,
    riskiestAssetScore,
    totalAssets,
    onGroupNodeClick,
    groupId,
    flipGraphOrientation,
    zoomLevel,
    isChildren = false,
    parentGroupId,
    securityGroupsCount,
    secondaryLinkList,
    primaryLinkList,
    securityGroupLinkList,
  } = props;
  const theme = useTheme();
  const { colors } = theme;

  const nodeIconWidth = r * 2 * 0.6;
  const nodeIconHeight = nodeIconWidth;

  const { nodeLabelTextWidth, nodeLabelTextPadding } = getGraphConstants(r);

  const {
    activeGroupId,
    activeSecondaryLinks,
    activePrimaryLinks,
    securityGroupNodeIsActive,
    securityGroupNodeId,
    activeSecurityGroupAssetCount,
    onActiveGroupIdChange,
    onActiveGroupParentIdChange,
    onHoveredGroupIdChange,
    onActiveSecondaryLinksChange,
    onActivePrimaryLinksChange,
    onActiveSGLinksChange,
    onSecurityGroupNodeIsActiveChange,
    onActiveSecurityGroupAssetCountChange,
  } = useCloudAssetsGraphContext();
  const [isHover, setIsHover] = useState(false);
  const isSGNode = securityGroupServiceTypes.includes(serviceType);

  const isActive = activeGroupId === groupId || (isSGNode && securityGroupNodeIsActive);

  const totalAssetCount =
    activeSecurityGroupAssetCount && isSGNode ? activeSecurityGroupAssetCount : totalAssets;

  const isConnected = useMemo(() => {
    if (!activeGroupId || activeSecondaryLinks?.length === 0) return true;

    const isConnectedViaPrimaryLink = activePrimaryLinks?.some(
      (link: SvcRisksApi.Schemas.Link) =>
        (link.sourceGroupID === activeGroupId && link.targetGroupID === groupId) ||
        link.sourceGroupID === groupId
    );

    const isConnectedViaSecondaryLink = activeSecondaryLinks?.some(
      (link: SvcRisksApi.Schemas.Link) =>
        link.sourceGroupID === groupId || link.targetGroupID === groupId
    );

    return isConnectedViaPrimaryLink || isConnectedViaSecondaryLink;
  }, [groupId, activeGroupId, activePrimaryLinks, activeSecondaryLinks]);

  const nodeOpacity = isActive || isConnected ? '1' : '0.4';

  const hoverTimer = useRef<number>();

  const nodeTruncatedName = useMemo(() => {
    if (text.length > 16) {
      return text.slice(0, 7) + '...' + text.slice(text.length - 1 - 6, text.length);
    } else {
      return text;
    }
  }, [text]);

  const nodeElement = useMemo(() => {
    if (serviceType === 'Internet') {
      return (
        <circle
          cx={x}
          cy={y}
          r={r}
          fill={colors[graphBGColorCode]}
          stroke={colors['gray:40']}
          strokeDasharray={4}
          strokeWidth="2"
        />
      );
    }
    return (
      <>
        {!!riskiestAssetScore && riskiestAssetScore > 70 && (
          <circle cx={x} cy={y} r={r} stroke={colors['red:50']} opacity="0.3" strokeWidth="17" />
        )}
        <circle
          cx={x}
          cy={y}
          r={r}
          fill={!isActive ? colors[graphBGColorCode] : colors['blue:60']}
          stroke={
            isHover && isActive
              ? colors['blue:40']
              : isHover
              ? colors['blue:50']
              : isActive
              ? colors['blue:60']
              : colors['gray:40']
          }
          strokeWidth="2"
        />
      </>
    );
  }, [serviceType, riskiestAssetScore, x, y, r, isActive, colors, isHover]);

  return (
    <g
      data-id={`graph-node-${groupId}`}
      onMouseEnter={() => {
        setIsHover(true);
        window.clearTimeout(hoverTimer.current);
        hoverTimer.current = window.setTimeout(() => {
          if (parentGroupId) {
            onHoveredGroupIdChange(parentGroupId);
          } else {
            onHoveredGroupIdChange(groupId);
          }
        }, 250);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        window.clearTimeout(hoverTimer.current);
        onHoveredGroupIdChange(undefined);
      }}
      onClick={() => {
        if (groupId) {
          if (groupId === activeGroupId) {
            onGroupNodeClick?.(undefined);
            onActiveGroupParentIdChange(undefined);
            onActiveGroupIdChange(undefined);
            onActiveSecurityGroupAssetCountChange(undefined);
            onSecurityGroupNodeIsActiveChange(false);
            onActiveSecondaryLinksChange([]);
            onActivePrimaryLinksChange([]);
            onActiveSGLinksChange([]);
          } else {
            onGroupNodeClick?.(groupId);
            onActiveGroupParentIdChange(parentGroupId || undefined);
            onActiveGroupIdChange(groupId);
            if (parentGroupId) {
              const activeSecondaryLinkList = findRelatedEdgesByGroupId(groupId, secondaryLinkList);
              onActiveSecondaryLinksChange(activeSecondaryLinkList);
              const activePrimaryLinkList = findRelatedEdgesByGroupId(
                parentGroupId,
                primaryLinkList
              );
              onActivePrimaryLinksChange(activePrimaryLinkList);
              if (securityGroupNodeId) {
                const activeSGLinkList = findRelatedEdgesByGroupId(
                  securityGroupNodeId,
                  securityGroupLinkList
                );
                onActiveSGLinksChange(activeSGLinkList);
              }
              onSecurityGroupNodeIsActiveChange(!!securityGroupsCount);
            } else {
              onActiveSecondaryLinksChange([]);
              onActivePrimaryLinksChange([]);
              onActiveSGLinksChange([]);
            }
            if (securityGroupsCount || isSGNode) {
              onActiveSecurityGroupAssetCountChange(securityGroupsCount);
            } else {
              onSecurityGroupNodeIsActiveChange(false);
            }
            pendoTrack(`REX_cloudAssetsGraphNode_click`, {
              assetType: serviceType,
              assetCount: totalAssets,
              riskScore: riskiestAssetScore,
              zoomLevel,
            });
          }
        }
      }}
      style={{ cursor: 'pointer' }}
      opacity={nodeOpacity}
    >
      {nodeElement}
      {(minZoomLevel(ZoomLevel.Wide, zoomLevel) || serviceType === 'Internet') && (
        <CloudAssetIcon
          serviceType={serviceType}
          serviceName={serviceName}
          width={nodeIconWidth}
          height={nodeIconHeight}
          x={x - nodeIconWidth / 2}
          y={y - nodeIconHeight / 2}
          fill={
            !isActive && !isHover
              ? colors['white:secondary']
              : !isActive && isHover
              ? colors['blue:50']
              : colors['white:emphasis']
          }
        />
      )}

      {!!riskiestAssetScore &&
        (getRiskLevel(riskiestAssetScore) === 'high' ||
          getRiskLevel(riskiestAssetScore) === 'medium') && (
          <circle
            cx={getNodeRiskIndicatorOffsetByZoomValue(zoomLevel, x)}
            cy={getNodeRiskIndicatorOffsetByZoomValue(zoomLevel, y)}
            r={getNodeRiskIndicatorRadiusByZoomValue(zoomLevel, r)}
            fill={colors[getRiskColor(riskiestAssetScore)]}
            stroke={colors[graphBGColorCode]}
            strokeWidth="2"
          />
        )}

      {!!text && minZoomLevel(ZoomLevel.Wide, zoomLevel) && (
        <foreignObject
          x={x - r - nodeLabelTextPadding}
          y={
            flipGraphOrientation && serviceType === 'Internet'
              ? y - r - nodeLabelTextHeight - 2
              : y + r + 2
          }
          width={nodeLabelTextWidth + nodeLabelTextPadding}
          height={nodeLabelTextHeight}
        >
          <Tooltip
            disabled={text === nodeTruncatedName}
            label={text}
            PopperProps={{ usePortal: true }}
            background="gray:80"
            color={colors['white:secondary']}
          >
            <Text
              color={colors['white:secondary']}
              fontSize="sm"
              lineHeight="sm"
              fontWeight="normal"
              textAlign="center"
              textTransform="capitalize"
              background={
                serviceType !== 'Internet' && !isChildren ? colors[graphBGColorCode] : undefined
              }
            >
              {nodeTruncatedName}
            </Text>
          </Tooltip>
        </foreignObject>
      )}
      {!!totalAssetCount && minZoomLevel(ZoomLevel.Medium, zoomLevel) && (
        <foreignObject width="40px" height="40px" x={x + r - 14} y={y - r}>
          <Badge
            backgroundColor="gray:90"
            borderColor="gray:40"
            borderStyle="solid"
            borderWidth="1px"
            color="white:emphasis"
            fontSize="xs"
            badgeContent={totalAssetCount > 999 ? '999+' : totalAssetCount}
          />
        </foreignObject>
      )}
    </g>
  );
};

CloudAssetsGraphNode.displayName = 'CloudAssetsGraphNode';
export default CloudAssetsGraphNode;
