import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import enResource from './locales/en/strings.json';
import jaResource from './locales/ja/strings.json';

export const defaultNS = 'translation';

const i18n = use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enResource,
      },
      ja: {
        translation: jaResource,
      },
    },
    lng: localStorage.getItem('uic_language') || 'en', // Locale integration with V1
    fallbackLng: 'en',
    defaultNS: 'translation',
    nonExplicitSupportedLngs: true,
    returnEmptyString: false,
    supportedLngs: ['en', 'ja'],
    debug: false,
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
