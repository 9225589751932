import { useRef, useEffect, useMemo } from 'react';
import {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  QueryKey,
  QueryFunction,
  useInfiniteQuery,
  InfiniteData,
  UseMutationOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { isAxiosError } from '../axios';
import { ErrorType } from '../../utils/errors';

export type InfiniteQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData>,
  'queryFn' | 'queryKey' | 'onSuccess'
> & {
  onSuccess?: (data: any) => void;
};
export type QueryOptions = Omit<UseQueryOptions<any, any, any, any>, 'queryFn' | 'queryKey'>;

export type MutationOptions = UseMutationOptions<any, any, any, any>;

export type APIErrorStatus<ErrorResponse = SvcRisksApi.Schemas.ErrorResponse> = {
  data: ErrorResponse;
  status?: number;
  headers?: unknown;
  url?: [method?: string, URI?: string];
  errorType: ErrorType;
};

export const useFlatInfiniteQuery = <TQueryFnData, TError, Entity>(
  queryKey: QueryKey,
  queryFn: QueryFunction<AxiosResponse<TQueryFnData>>,
  pageResolver: (page: AxiosResponse<TQueryFnData> | undefined) => Entity[] | undefined,
  options?: InfiniteQueryOptions<any, any, any>,
  fetchAllPages = false
) => {
  // Guard against infinite loops by blocking maximum fetched pages
  const currentFetchAttempts = useRef(0);

  const onSuccess = options?.onSuccess;
  if (fetchAllPages && options) {
    // Remove some default options because they no longer trigger at appropriate times
    // Remove onSuccess because it triggers after every page load. We want it to trigger __just once__ after all pages are laoded
    delete options.onSuccess;
  }
  if (!fetchAllPages && options) {
    // Overwrite option onSuccess callback to return a flat array
    if (onSuccess) {
      options.onSuccess = (data: InfiniteData<AxiosResponse<TQueryFnData>>) =>
        onSuccess(data?.pages?.flatMap((page) => pageResolver(page) as Entity[]) ?? []);
    }
  }

  const {
    data,
    hasPreviousPage = false,
    hasNextPage = false,
    ...rest
  } = useInfiniteQuery<AxiosResponse<TQueryFnData> | undefined, TError>(queryKey, queryFn, {
    getNextPageParam: (lastPage: any) => lastPage?.data?.next,
    ...options,
  });

  const isFetchingAllPages =
    (rest.isLoading || rest.isFetching || hasNextPage) && currentFetchAttempts.current <= 10;

  useEffect(() => {
    if (!fetchAllPages) return;
    //  To prevent bug like infinite refetching we will only try fetch next up to 10 times.
    if (currentFetchAttempts.current > 10) {
      console.warn('Aborting future fetch next page attempts');
      return;
    }

    if (!(rest.isFetching || rest.isFetchingNextPage) && hasNextPage) {
      currentFetchAttempts.current++;
      rest.fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.isFetching, rest.isFetchingNextPage, hasNextPage, fetchAllPages]);

  useEffect(() => {
    // Trigger an onSuccess callback only when all pages have been fetched if `fetchAllPages` is true
    if (!fetchAllPages) return;
    if (isFetchingAllPages || !rest.isSuccess) return;

    onSuccess?.(data?.pages?.flatMap((page) => pageResolver(page) as Entity[]) ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingAllPages, fetchAllPages, rest.error, rest.isSuccess]);

  return {
    ...rest,
    data: useMemo(
      () => data?.pages?.flatMap((page) => pageResolver(page) as Entity[]),
      [data?.pages, pageResolver]
    ),
    hasPreviousPage,
    hasNextPage,
    hasFetchedAllPages: !isFetchingAllPages,
  };
};

export const catchAPIErrorAndLog = (msg: string, errorType: ErrorType) => (error: unknown) => {
  let resp: APIErrorStatus;
  if (isAxiosError(error)) {
    if (error.response) {
      // Request made and server responded
      resp = {
        status: error.response.status,
        url: [error.config?.method, error.config?.url],
        data: error.response.data as SvcRisksApi.Schemas.ErrorResponse,
        headers: error.response.headers,
        errorType,
      };
      console.warn(`${msg}: received 4xx/5xx response`, resp);
    } else {
      // The request was made but no response was received
      resp = {
        url: [error.config?.method, error.config?.url],
        data: { message: error.message },
        errorType,
      };
      console.warn(`${msg}: no response received`, resp);
    }
  } else {
    // Something happened in setting up the request that triggered an Error
    resp = { data: { message: (error as Error | undefined)?.message ?? '' }, errorType };
    console.warn(`${msg}: no request made`);
    console.warn(error);
  }

  throw resp;
};
