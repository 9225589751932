import { Stack, Text } from '@tonic-ui/react';
import { Trans } from 'react-i18next';
import { generateErrorDescription } from '../../utils/errors';

const GraphApiErrorOverlay = (props: { error: any }): JSX.Element => {
  const { error } = props;

  return (
    <Stack
      data-id="graph-api-error-overlay"
      height="100%"
      alignItems="center"
      justifyContent="center"
      spacing="2x"
    >
      {error.status !== 404 ? (
        <>
          <Text textAlign="center" size="lg" color="white:primary">
            <Trans i18nKey="riskGraph.error.message" />
          </Text>
          <Text textAlign="center" size="sm" color="white:secondary">
            <Trans i18nKey="riskGraph.error.graphAPIError" />
          </Text>
          <Text textAlign="center" size="sm" color="white:secondary">
            {generateErrorDescription(error.status)}
          </Text>
        </>
      ) : (
        <Text color="white:secondary" size="sm">
          <Trans i18nKey="riskGraph.empty.accountHasNoProcessedData" />
        </Text>
      )}
    </Stack>
  );
};

GraphApiErrorOverlay.displayName = 'GraphApiErrorOverlay';
export default GraphApiErrorOverlay;
