import { Stack, Flex, Icon, Text } from '@tonic-ui/react';
import { WarningTriangleIcon } from '@tonic-ui/react-icons';
import { Trans } from 'react-i18next';
import { generateErrorCode } from '../../utils/errors';

interface GeneralErrorOverlayProps {
  error?: unknown;
  compact?: boolean;
}

const GeneralErrorOverlay = ({ compact = false }: GeneralErrorOverlayProps) => {
  if (compact) {
    return (
      // Compact view of the general error overlay
      <Stack spacing="4px">
        <Flex>
          <Icon as={WarningTriangleIcon} color="orange:50" />
          <Text size="sm" marginLeft="1x" color="white:primary">
            <Trans i18nKey="riskGraph.error.message" />
          </Text>
        </Flex>
        <Text size="sm" color="white:primary">
          <Trans i18nKey="riskGraph.error.graphAPIError" />
        </Text>
      </Stack>
    );
  }
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" spacing="2x">
      <Text textAlign="center" size="lg" color="white:primary">
        <Trans i18nKey="riskGraph.error.message" />
      </Text>
      <Text textAlign="center" size="sm" color="white:secondary">
        <Trans i18nKey="riskGraph.error.graphAPIError" />
      </Text>
      <Text textAlign="center" size="sm" color="white:secondary">
        <Trans i18nKey="riskGraph.error.referenceID" />: {generateErrorCode('F')}
      </Text>
    </Stack>
  );
};

GeneralErrorOverlay.displayName = 'GeneralErrorOverlay';
export default GeneralErrorOverlay;
