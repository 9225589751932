import _axios, { AxiosError } from 'axios';
import { UICKeys } from '../../constants';

export const UIC_TOKEN_KEY = UICKeys.uicToken;

export const axios = _axios.create({
  headers: {
    'api-version': 'v1',
    Accept: 'application/json',
    // Add UIC token to default headers
  },
  timeout: 30000, // 30seconds
});

axios.interceptors.request.use((config) => {
  const { headers } = config;
  // Add UIC token to default headers
  const uicToken = localStorage.getItem(UIC_TOKEN_KEY);
  if (uicToken) {
    headers[UIC_TOKEN_KEY] = uicToken;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check for error from UIC indicating that the token is invalid
    if (
      error.response &&
      error.response.status === 401 &&
      window.parent &&
      window.parent !== window
    ) {
      window.parent.location.reload();
    }
    return Promise.reject(error);
  }
);

export const isAxiosError = (error: unknown): error is AxiosError => {
  return (error as AxiosError)?.isAxiosError;
};
