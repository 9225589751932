const ceilRiskScore = (riskScore: number) => {
  let result = 0;
  if (typeof riskScore === 'number') {
    if (riskScore > 100) {
      result = 100;
    } else {
      result = riskScore;
    }
  }
  return result;
};

const flattenCloudAssetProfile = (
  assetProfile: BigTableCloudAssetProfileResponse | undefined
): BigTableCloudAssetProfile => {
  const result = {} as BigTableCloudAssetProfile;
  if (Array.isArray(assetProfile)) {
    assetProfile.forEach((item) => {
      Object.entries(item).forEach(([key, val]) => {
        (result as any)[key] = val;
      });
    });
    return result;
  } else if (typeof assetProfile === 'object' && assetProfile) {
    return assetProfile;
  } else {
    return result;
  }
};

export { flattenCloudAssetProfile, ceilRiskScore };
