/**
 * This helper is used to generate predictable error codes.
 * The error code synthax is as follows:
 * REX_G_XXX_XX
 * Where:
 * 1. REX is the product code.
 * 2. (A)PI | (F)rontend | (G)eneric indicate the component.
 * 3. The next 3 digits are the HTTP code or 000 is returned by default.
 * 4. The last 2 digits are the unique error number (See: ErrorType).
 **/
import { t } from 'i18next';

export enum ErrorType {
  GENERIC = '01',
  ACCOUNTS = '02',
  CLOUD_PROVIDERS = '03',
  ASSET_GROUPS = '04',
  ASSET_GROUPS_LIST_ASSETS = '05',
  ACCOUNT_DETAILS = '06',
  MODIFY_ASSET_GROUP = '07',
  CAM_ACCOUNT_STATUS = '08',
  ASSET_GRAPH_DEFINITIONS = '09',
  BIG_TABLE_ASSET_DETAIL = '10',
  BIG_TABLE_ASSET_PROFILE = '11',
  RISK_HISTORY = '12',
  CRITICALITY_INFO = '13',
}

type ErrorComponent = 'F' | 'A' | 'G';

export const generateErrorCode = (
  component: ErrorComponent = 'G',
  httpStatus: number | string = '000',
  errorType: ErrorType = ErrorType.GENERIC
): string => {
  return `REX_${component}_${httpStatus}_${errorType}`;
};

const httpStatusDescriptions: { [key: number]: string } = {
  400: t('riskGraph.error.httpStatusDescriptions.400'),
  401: t('riskGraph.error.httpStatusDescriptions.401'),
  403: t('riskGraph.error.httpStatusDescriptions.403'),
  404: t('riskGraph.error.httpStatusDescriptions.404'),
  500: t('riskGraph.error.httpStatusDescriptions.500'),
  503: t('riskGraph.error.httpStatusDescriptions.503'),
};
export const generateErrorDescription = (httpStatus: number): string => {
  return httpStatusDescriptions[httpStatus];
};
