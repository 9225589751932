import ReactDOM from 'react-dom';
import RiskExplorerApp from './App';

const appName = 'riskExplorerView'; // reference appName in SASE. Any AppName should work.
const qiankunWindow = typeof window !== 'undefined' ? window?.proxy || window : ({} as Window);
let root: HTMLElement | null = null;

const getRoot = (props: MicroAppProps | any = {}) => {
  const rootElement: HTMLElement | null = props?.container
    ? props.container.querySelector('#root')
    : document.querySelector('#root');

  if (!rootElement) throw new Error('Could not find root element to render RiskExplorer App');

  return (root = rootElement);
};

const render = (props: MicroAppProps | any = {}) => {
  const root = getRoot(props);
  // eslint-disable-next-line react/no-deprecated,import/no-named-as-default-member
  ReactDOM.render(<RiskExplorerApp {...props} />, root);
};

const startMsw = async () => {
  const { worker } = await import('./mocks/browser');
  await worker.start({});
};

if (qiankunWindow?.__POWERED_BY_QIANKUN__) {
  // export qiankun bootstrap/mount/unmount life-cycles
  qiankunWindow[appName] = {
    async mount(props: MicroAppProps) {
      return render(props);
    },
    async bootstrap() {},
    async unmount() {
      // eslint-disable-next-line react/no-deprecated,import/no-named-as-default-member
      if (root) ReactDOM.unmountComponentAtNode(root);
    },
  };
} else if (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test') {
  // render the app without qiankun
  // Start the mocking conditionally.
  startMsw().then(() => {
    render({});
  });
} else {
  // do nothing
}
