const getTimezoneOffset = (timezone: string) => {
  if (!timezone) {
    return 0;
  }
  let offset = 0;
  const pattern = /^([+-])(\d+):(\d+)$/;
  const matches = timezone.match(pattern);
  if (matches) {
    const flag = matches[1] === '-' ? 1 : -1;
    const hour = parseInt(matches[2], 10);
    const min = parseInt(matches[3], 10);
    offset = flag * (hour * 60 + min);
  }
  return offset;
};
const UICHasDateFormat = typeof window.top?.APP?.$trend?.uicDateFormat === 'function';
const UICHasDateFormatOffset = typeof window.top?.APP?.$trend?.uicDateFormatOffset === 'function';
const uicDateFormat = (...params: unknown[]) => {
  return (window.top as Window).APP.$trend.uicDateFormat(...params);
};
const getUICTimezoneLoc = () => {
  let result = '';
  try {
    const json = JSON.parse(localStorage.getItem('uic_consoleSettings') as string);
    result = json?.timezone_identifier;
  } catch (e) {
    result = '';
  }
  return result;
};
const getUICTimezone = () => {
  const result = localStorage.getItem('uic_timezone');
  if (result) {
    return result;
  }
  const city = getUICTimezoneLoc();
  if (typeof window.top?.APP?.$dayjs === 'function' && city) {
    return window.top.APP.$dayjs().tz(city).format('Z');
  }
  return UICHasDateFormatOffset
    ? (window.top as Window).APP.$trend.uicDateFormatOffset(Date.now())
    : null;
};
// get time zone offset in minutes
export const getUICTimezoneOffset = ({
  timestamp,
  timezone,
}: {
  timestamp: number;
  timezone?: string;
}) => {
  if (UICHasDateFormat) {
    const UICTimeStr = uicDateFormat(timestamp);
    if (typeof UICTimeStr === 'string' && UICTimeStr.length > 1) {
      const UTCTimestamp = new Date(UICTimeStr + 'Z').valueOf();
      const offset = Math.round((timestamp - UTCTimestamp) / 60000);
      return offset;
    }
  }
  // fallback: use timezone
  if (!timezone) {
    timezone = getUICTimezone();
  }
  return getTimezoneOffset(timezone as string);
};
