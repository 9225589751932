import { useCallback, FocusEvent } from 'react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
  Icon,
  PopoverProps,
} from '@tonic-ui/react';

import { InfoOIcon } from '@tonic-ui/react-icons';

type InfoPopTipProps = {
  trigger?: PopoverProps['trigger'];
  label?: string;
  width: string;
  iconColor?: 'rgba(255,255,255,0.6)';
  iconRest?: Record<string, unknown>;
  placement: PopoverProps['placement'];
  hideArrow?: boolean;
  onOpen?: () => void;
  contentProps?: Record<string, unknown>;
  bodyProps?: Record<string, unknown>;
};

export default function InfoPopTip({
  trigger = 'hover',
  label = '',
  width = 'auto',
  iconColor = 'rgba(255,255,255,0.6)',
  iconRest = {},
  placement = 'right-start',
  hideArrow = false,
  onOpen = () => {},
  contentProps = {},
  bodyProps = {},
}: InfoPopTipProps) {
  // callbacks
  const handleTriggerBlur = useCallback(
    (e: FocusEvent) => {
      if (trigger === 'hover') {
        e.preventDefault();
      }
    },
    [trigger]
  );

  return (
    <Popover
      placement={placement}
      trigger={trigger}
      enterDelay={200}
      onOpen={onOpen}
      {...(hideArrow && { arrow: false })}
    >
      <PopoverTrigger>
        <Icon as={InfoOIcon} color={iconColor} {...iconRest} onBlur={handleTriggerBlur} />
      </PopoverTrigger>
      <PopoverContent
        style={{ width, maxWidth: width === 'auto' ? '288px' : 'unset' }}
        {...contentProps}
      >
        <PopoverBody {...bodyProps}>{label}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
