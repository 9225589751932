// Localstorage keys
export const UICKeys = {
  uicLanguage: 'uic_language',
  uicTimeZone: 'uic_timezone',
  uicCompanyGuid: 'uic_companyGUID',
  uicUserGuid: 'uic_userGUID',
  uicRoleGuid: 'uic_roleGUID',
  uicRole: 'uic_role',
  uicToken: 'uic-token',
};
