import { Badge, Tooltip } from '@tonic-ui/react';
import { Trans } from 'react-i18next';
import { getAccountsRiskColor } from './helpers';

export type RiskScoreIndicatorProps = React.SVGProps<SVGSVGElement> & {
  riskScore: number;
};

const RiskScoreIndicator = (props: RiskScoreIndicatorProps): JSX.Element => {
  const { riskScore } = props;

  return (
    <Tooltip
      background="gray:80"
      color="white:secondary"
      label={<Trans i18nKey="highestRiskScore" values={{ highestRiskScore: riskScore }} />}
      placement="right"
    >
      <Badge
        variant="dot"
        backgroundColor={getAccountsRiskColor(riskScore)}
        boxShadow="none"
        border="none"
        marginRight="2x"
      />
    </Tooltip>
  );
};

export default RiskScoreIndicator;
