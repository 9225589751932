import { Box, Flex, Icon, Link, Space } from '@tonic-ui/react';
import { ExternalLinkIcon, InfoIcon } from '@tonic-ui/react-icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LEVEL_MAP } from './constants';
import SignificantTags from './SignificantTags';

type AssetCriticalityProps = {
  inMgmtScope?: boolean;
  loading: boolean;
  data: CriticalityInfoResponse['data'] | undefined;
  assetID: string;
};

const AssetCriticality = ({ loading, data, assetID, ...rest }: AssetCriticalityProps) => {
  // i18n
  const { t } = useTranslation();
  // cached states
  const hasTagData = useMemo(() => {
    return (
      Object.keys(data?.absoluteImpactTag || {}).length > 0 ||
      Object.keys(data?.highImpactTag || {}).length > 0 ||
      Object.keys(data?.lowImpactTag || {}).length > 0
    );
  }, [data]);

  return (
    <Box {...rest}>
      <Box marginTop="4x" fontSize="md" lineHeight="md">
        {t('riskGraph.assetsDetailsDrawer.assetCriticality')}
      </Box>
      <Box marginTop="6px" fontSize="xl" lineHeight="xl" marginBottom="3x">
        {t(LEVEL_MAP[data?.criticalScoreLevel ?? 'medium'] || LEVEL_MAP.medium)}
      </Box>
      <Link
        target="_blank"
        rel="noopener noreferrer"
        fontSize="sm"
        lineHeight="sm"
        href={`/#/app/asd?assetType=cloudAsset&assetId=${assetID}&tab=asset_profile&from=blank`}
      >
        {t(`riskGraph.assetsDetailsDrawer.links.customizeCriticality`)}
        <Space width="2x" />
        <Icon as={ExternalLinkIcon} />
      </Link>
      {!loading && !hasTagData && (
        <Flex
          gap="3x"
          border="thin solid"
          borderColor="gray:60"
          filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          borderRadius="sm"
          padding="3x"
          marginTop="4x"
        >
          <Icon as={InfoIcon} color="blue:40" marginTop="2px" />
          <Box color="white:secondary">
            {t('riskGraph.assetsDetailsDrawer.criticality.defaultDetail')}
          </Box>
        </Flex>
      )}
      <Box>
        {/* tag list */}
        {!loading && data?.isSystemDefined !== false && (
          <SignificantTags
            absoluteTags={data?.absoluteImpactTag}
            highTags={data?.highImpactTag}
            lowTags={data?.lowImpactTag}
            assetID={assetID}
          />
        )}
      </Box>
    </Box>
  );
};
export default AssetCriticality;
