import { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
} from '@tonic-ui/react';
import { Global, css } from '@emotion/react';

import TruncateWithTooltip from '../TruncateWithTooltip';

type RiskExplorerDrawerProps = {
  closeOnOutsideClick?: boolean;
  closeOnEsc?: boolean;
  ensureFocus?: boolean;
  isOpen: boolean;
  isClosable?: boolean;
  onClose?: () => void;
  placement?: 'left' | 'right' | 'top' | 'bottom';
  size?: 'auto' | 'sm' | 'md' | 'lg' | 'full' | number;
  header?: any;
  body: any;
  footer?: any;
  showOverlay?: boolean;
  id: string;
};

const RiskExplorerDrawer = (props: RiskExplorerDrawerProps) => {
  const {
    closeOnOutsideClick = true,
    closeOnEsc = true,
    ensureFocus = false,
    isOpen,
    isClosable = true,
    onClose,
    placement = 'right',
    size = 'auto',
    header,
    body,
    footer,
    showOverlay = false,
    id,
  } = props;

  const [isDrawerOpened, setIsDrawerOpened] = useState<boolean>(isOpen);

  const defaultOnDrawerClose = () => {
    setIsDrawerOpened(false);
  };

  const drawerSize = typeof size === 'number' ? 'auto' : size;

  return (
    <Drawer
      closeOnOutsideClick={closeOnOutsideClick}
      closeOnEsc={closeOnEsc}
      ensureFocus={ensureFocus}
      isOpen={isDrawerOpened}
      onClose={onClose || defaultOnDrawerClose}
      placement={placement}
      size={drawerSize}
      data-id={id}
    >
      <Global
        styles={css`
          body {
            overflow: hidden;
          }
        `}
      />
      {showOverlay && <DrawerOverlay />}
      <DrawerContent {...(typeof size === 'number' && { width: size })}>
        {header && (
          <DrawerHeader paddingRight={isClosable ? '12x' : '4x'}>
            <TruncateWithTooltip
              text={header}
              tooltipStyles={{ PopperProps: { usePortal: true } }}
            />
          </DrawerHeader>
        )}
        <DrawerBody>{body}</DrawerBody>
        {footer && <DrawerFooter>{footer}</DrawerFooter>}
        {isClosable && <DrawerCloseButton data-id="drawer-close-button" />}
      </DrawerContent>
    </Drawer>
  );
};

RiskExplorerDrawer.displayName = 'RiskExplorerDrawer';
export default RiskExplorerDrawer;
