import { AwsRum, AwsRumConfig } from 'aws-rum-web';

const APPLICATION_VERSION: string = '1.0.0';
const APPLICATION_REGION: string = 'us-east-1';
let awsRum: AwsRum | undefined;

const commonRUMConfigAttributes: AwsRumConfig = {
  sessionSampleRate: 1,
  allowCookies: true,
  enableXRay: false,
  telemetries: ['errors', 'performance', 'http'],
  endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
  pageIdFormat: 'PATH_AND_HASH',
  pagesToInclude: [/\?tab=4&view=graphview/],
};

const toggleRUMBasedOnGraphView = () =>
  window.location.search.includes('graphview') ? awsRum?.enable() : awsRum?.disable();

const CONFIGURATION = {
  ...commonRUMConfigAttributes,
  // Find in the CloudFormation Stack's Resources.
  // Open link to the role ARN(Logical ID: rumunauthenticatedrole...) and copy the value of the "Arn" field
  guestRoleArn:
    'arn:aws:iam::865740713803:role/web-app-risk-explorer-pro-RumUnauthenticatedRole60F-fAyLJu1TqZZN',
  // Find the Cognito indentity pool ID ( Logical ID:  rumidentitypool...) and copy the "Physical ID" value
  identityPoolId: 'us-east-1:bd6b56bb-fd25-4fb6-a86d-45bd207b4916',
};

// Find in the Cloudwatch/RUM/List View. Click on the app monitor, select the "Configuration" tab. Read from "App Monitor ID" field under Details section
const APPLICATION_ID = '3fefcae0-d492-421f-a4e7-06a50b6b6ef0';

const AWSRumInitialize = () => {
  try {
    window.removeEventListener('popstate', toggleRUMBasedOnGraphView);
    // Parent SASE app triggers popstate when it updates URL as user navigates between tabs. While on other tabs, REX will still be loaded, but hidden from view
    window.addEventListener('popstate', toggleRUMBasedOnGraphView);
    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, CONFIGURATION);
    return awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
    console.error(error);
  }
};

export default AWSRumInitialize;
