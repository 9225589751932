import { Fragment, PropsWithChildren, useState, FocusEvent } from 'react';
import {
  Text,
  Flex,
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverBody,
  Box,
  Link,
  Divider,
  Scrollbar,
  Stack,
  Button,
  Icon,
} from '@tonic-ui/react';
import { WarningMinorIcon } from '@tonic-ui/react-icons';
import { Trans, useTranslation } from 'react-i18next';
import TruncateWithTooltip from '../../TruncateWithTooltip';
import RiskScoreIndicator from '../../RiskScoreIndicator';
import GlobalFilter from '../../table/GlobalFilter';

type PopoverBodyItemProps = PropsWithChildren<{
  labelTransKey: string;
}>;

const PopoverBodyItem = (props: PopoverBodyItemProps) => {
  const { labelTransKey, children } = props;

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Text whiteSpace="nowrap">
        <Trans i18nKey={labelTransKey} />
      </Text>
      <Box minWidth="0">{children}</Box>
    </Flex>
  );
};

type CloudAssetsGraphLinkLabelProps = {
  assetSecurityGroups: SvcRisksApi.Schemas.AssetSecurityGroup[] | undefined;
  pathCenterPointCoordinates: { x: number; y: number };
  labelWidth: number;
  labelHeight: number;
  relatedEdgeIsDimmed?: boolean;
};

const CloudAssetsGraphLinkLabel = (props: CloudAssetsGraphLinkLabelProps) => {
  const { i18n, t } = useTranslation();
  const {
    assetSecurityGroups,
    pathCenterPointCoordinates,
    labelWidth,
    labelHeight,
    relatedEdgeIsDimmed,
  } = props;

  const listDelimiter = i18n.language === 'ja' ? '、' : ', ';

  const hasOneSGAllOpenPorts = (securityGroupsList: SvcRisksApi.Schemas.SecurityGroupInfo[]) => {
    return securityGroupsList.some((securityGroup) => securityGroup.sgOpenPorts?.includes('-1'));
  };

  const [filteredSecurityGroups, setFilteredSecurityGroups] = useState(assetSecurityGroups);

  //TODO: Implement isAssetSupported logic
  const isAssetSupported = true;

  const handleGlobalFilterChange = (globalFilter: string) => {
    if (!assetSecurityGroups) return;
    if (!globalFilter) {
      setFilteredSecurityGroups(assetSecurityGroups);
      return;
    }

    const results = [];
    const lowercaseGlobalFilter = globalFilter.toLowerCase();

    for (const assetSecurityGroup of assetSecurityGroups) {
      const isAssetNameMatch = assetSecurityGroup.assetName
        ?.toLowerCase()
        .includes(lowercaseGlobalFilter);

      const hasMatchInSecurityGroups = assetSecurityGroup.securityGroups.some(
        (sg: SvcRisksApi.Schemas.SecurityGroupInfo) =>
          sg.sgOpenPorts.some((port: string) => port.includes(globalFilter)) ||
          sg.sgName.toLowerCase().includes(lowercaseGlobalFilter)
      );

      if (isAssetNameMatch || hasMatchInSecurityGroups) {
        results.push(assetSecurityGroup);
      }
    }
    setFilteredSecurityGroups(results);
  };

  const preventPopoverClosure = (e: FocusEvent) => e.preventDefault();

  return (
    <Popover trigger="hover" placement="left">
      <PopoverTrigger onBlur={preventPopoverClosure}>
        <foreignObject
          x={pathCenterPointCoordinates.x - labelWidth * 0.5}
          y={pathCenterPointCoordinates.y - labelHeight * 0.5}
          width={labelWidth}
          height={labelHeight}
        >
          {!!assetSecurityGroups && (
            <Button
              height={labelHeight - 1}
              width={labelWidth - 1}
              backgroundColor="gray:90"
              textAlign="center"
              color="white:emphasis"
              fontSize="3x"
              transition="all 0.2s"
              cursor="default"
              paddingX="1x"
              paddingY="1x"
              border="1px solid"
              borderRadius="4px"
              borderColor="red:50"
              opacity={relatedEdgeIsDimmed ? '0.2' : '1'}
              _hover={{
                backgroundColor: 'gray:70',
                border: '1px solid',
                borderColor: 'red:50',
                opacity: '1',
              }}
            >
              <Icon as={WarningMinorIcon} />
            </Button>
          )}
        </foreignObject>
      </PopoverTrigger>
      {!!assetSecurityGroups && (
        <PopoverContent PopperProps={{ usePortal: true }} minWidth="500px">
          <PopoverHeader>
            <Trans i18nKey="edgesLabels.details" />
          </PopoverHeader>
          <PopoverBody display="flex" flexDirection="column" gap="1x" color="white:secondary">
            {!!assetSecurityGroups?.length && (
              <Stack fontSize="xs">
                {assetSecurityGroups.length > 10 && (
                  <Box marginBottom="2x">
                    <GlobalFilter
                      setGlobalFilter={handleGlobalFilterChange}
                      placeholder={t('edgesLabels.search')}
                      autoFocus
                    />
                  </Box>
                )}

                {filteredSecurityGroups?.length === 0 ? (
                  <Flex height="100%" alignItems="center" justifyContent="center" minHeight="400px">
                    <Trans i18nKey="edgesLabels.noResults" />
                  </Flex>
                ) : (
                  <Scrollbar
                    height="auto"
                    maxHeight="400px"
                    overflow="scroll"
                    minHeight={assetSecurityGroups.length > 10 ? '400px' : ''}
                  >
                    {filteredSecurityGroups?.map((assetSecurityGroup, index, list) => (
                      <Stack key={assetSecurityGroup.assetName} marginTop="1x">
                        <Flex alignItems="center" paddingRight="2x">
                          <Flex>
                            <RiskScoreIndicator riskScore={assetSecurityGroup.assetRiskScore} />
                          </Flex>
                          {isAssetSupported ? (
                            <Link
                              href={`/es/cloudAsset/${assetSecurityGroup.assetID}?tab=asset_graph`}
                              onClick={(event) => {
                                event.preventDefault();
                                window.RI_HISTORY?.push(
                                  `/es/cloudAsset/${assetSecurityGroup.assetID}?tab=asset_graph`
                                );
                              }}
                              minWidth="0"
                            >
                              <TruncateWithTooltip
                                text={assetSecurityGroup.assetName}
                                tooltipStyles={{
                                  PopperProps: { usePortal: true },
                                }}
                              />
                            </Link>
                          ) : (
                            <Link
                              href={`/#/app/asd?assetType=cloudAsset&assetId=${assetSecurityGroup.assetID}&from=blank`}
                              onClick={(event) => {
                                event.preventDefault();
                                window.RI_HISTORY?.push(
                                  `/es/cloudAsset/${assetSecurityGroup.assetID}`
                                );
                              }}
                              minWidth="0"
                            >
                              <TruncateWithTooltip
                                text={assetSecurityGroup.assetName}
                                tooltipStyles={{
                                  PopperProps: { usePortal: true },
                                }}
                              />
                            </Link>
                          )}
                        </Flex>

                        <Flex justifyContent="flex-start">
                          <PopoverBodyItem labelTransKey="edgesLabels.openPorts">
                            {hasOneSGAllOpenPorts(assetSecurityGroup.securityGroups) ? (
                              <Text color="white:primary" paddingLeft="1x" display="inline">
                                <Trans i18nKey="edgesLabels.openPortsALL" />
                              </Text>
                            ) : (
                              assetSecurityGroup.securityGroups
                                .flatMap(
                                  (securityGroup: SvcRisksApi.Schemas.SecurityGroupInfo) =>
                                    securityGroup.sgOpenPorts
                                )
                                .map((openPort: string, index: number, list: string[]) => (
                                  <Fragment key={index}>
                                    <Text
                                      color="white:primary"
                                      paddingLeft={index === 0 ? '1x' : '0'}
                                      display="inline"
                                    >
                                      {openPort}
                                      {index < list.length - 1 && <>{listDelimiter}</>}
                                    </Text>
                                  </Fragment>
                                ))
                            )}
                          </PopoverBodyItem>
                        </Flex>

                        <Flex justifyContent="flex-start" marginBottom="1x">
                          <PopoverBodyItem labelTransKey="edgesLabels.securityGroups">
                            {assetSecurityGroup.securityGroups.map(
                              (
                                securityGroup: SvcRisksApi.Schemas.SecurityGroupInfo,
                                index: number,
                                list: SvcRisksApi.Schemas.SecurityGroupInfo[]
                              ) => (
                                <Fragment key={securityGroup.sgName}>
                                  <Link
                                    marginLeft="1x"
                                    maxWidth="65px"
                                    href={`/es/cloudAsset/${securityGroup.sgAssetID}?tab=asset_graph`}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      window.RI_HISTORY?.push(
                                        `/es/cloudAsset/${securityGroup.sgAssetID}?tab=asset_graph`
                                      );
                                    }}
                                  >
                                    <TruncateWithTooltip
                                      text={securityGroup.sgName}
                                      tooltipStyles={{
                                        PopperProps: { usePortal: true },
                                      }}
                                    />
                                  </Link>
                                  {index < list.length - 1 && (
                                    <Text
                                      color="white:primary"
                                      paddingLeft={index === 0 ? '1x' : '0'}
                                      display="inline"
                                    >
                                      {listDelimiter}
                                    </Text>
                                  )}
                                </Fragment>
                              )
                            )}
                          </PopoverBodyItem>
                        </Flex>
                        {index < list.length - 1 && <Divider />}
                      </Stack>
                    ))}
                  </Scrollbar>
                )}
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      )}
    </Popover>
  );
};

CloudAssetsGraphLinkLabel.displayName = 'CloudAssetsGraphLinkLabel';
export default CloudAssetsGraphLinkLabel;
