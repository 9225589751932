import { useRef } from 'react';
import { Box, Flex } from '@tonic-ui/react';
import { useResize } from './useResize';

export type SplitViewProps = {
  top: JSX.Element | false;
  bottom: JSX.Element;
  isFullScreen: boolean;
};

const SplitView = (props: SplitViewProps) => {
  const splitViewTopBoxRef = useRef<HTMLInputElement>(null);
  const { top, bottom, isFullScreen } = props;
  const { height, handleMouseDown } = useResize(splitViewTopBoxRef);

  const numberOfCircles = 4;
  const Circles = () => {
    return <Box width="3px" height="3px" backgroundColor="gray:60" borderRadius="sm"></Box>;
  };

  return (
    <Flex flexDirection="column" height="100%">
      <Box
        ref={splitViewTopBoxRef}
        overflow="hidden"
        style={{
          minHeight: isFullScreen ? '0' : '20%',
          height: isFullScreen ? '0%' : height === 0 ? '44%' : height,
        }}
      >
        {top}
      </Box>
      {!isFullScreen && (
        <Flex
          height="9px"
          borderTop="2px solid"
          borderBottom="2px solid"
          borderTopColor="gray:60"
          borderBottomColor="gray:60"
          cursor="row-resize"
          onMouseDown={handleMouseDown}
          justifyContent="center"
          alignItems="center"
        >
          {Array.from({ length: numberOfCircles }, (_, i) => (
            <Circles key={i} />
          ))}
        </Flex>
      )}

      <Box flex="1" minHeight="38%" {...(isFullScreen && { style: { height: '100%' } })}>
        {bottom}
      </Box>
    </Flex>
  );
};

SplitView.displayName = 'SplitView';
export default SplitView;
