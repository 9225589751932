import { useContext, useMemo, useEffect } from 'react';
import {
  Flex,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  useColorMode,
  useColorStyle,
  Spinner,
  Stack,
  Box,
  SearchInput,
  SearchInputProps,
  Grid,
  Link,
  Badge,
  Space,
} from '@tonic-ui/react';
import { useTranslation, Trans } from 'react-i18next';
import type { ColumnDef } from '@tanstack/react-table';
import { getAccountsRiskColor } from '../helpers';
import { API } from '../../services';
import { Table, SimpleTable, SimpleTableColumnDef } from '../table';
import TruncateWithTooltip from '../TruncateWithTooltip';
import { AppContext } from '../../store/store';

type CloudAccountWithConnectionState = SvcRisksApi.Schemas.Account & { state: string | undefined };

const AccountSearchInput = (props: SearchInputProps) => {
  return <SearchInput maxWidth="480px" {...props} />;
};
AccountSearchInput.displayName = 'AccountSearchInput';

const CloudAssetsContainer = () => {
  const { t } = useTranslation();
  const [colorMode] = useColorMode();
  const [colorStyle] = useColorStyle({ colorMode });

  const [{ availableProviders, selectedProvider, selectedAccount }, dispatchGlobalStoreEvent] =
    useContext(AppContext);

  const availableProvidersKeys = availableProviders.map(
    (availableProvider) => availableProvider.cloudProvider
  ) as SvcRisksApi.Parameters.Provider[];

  const allProviders = selectedProvider === 'All';

  const { data: cloudProvidersData, isLoading: cloudProviderDataIsLoading } =
    API.useGetListCloudProviders({ useErrorBoundary: true });
  const { data: connectionStatusData, isLoading: connectedAccountDataIsLoading } =
    API.useGetConnectionStatus(availableProvidersKeys, selectedProvider);
  const { data: cloudAccountData, isLoading: cloudAccountDataIsLoading } = API.useGetListAccounts(
    {
      availableProviders: availableProvidersKeys,
      provider: selectedProvider,
    },
    { useErrorBoundary: true }
  );

  useEffect(() => {
    if (cloudProvidersData?.data) {
      const availableCloudProviders = cloudProvidersData?.data.filter(
        (cloudProvider) => cloudProvider.cloudProvider !== 'All'
      );
      dispatchGlobalStoreEvent({
        type: 'SET_PROVIDERS',
        payload: availableCloudProviders,
      });
    }
  }, [cloudProvidersData, dispatchGlobalStoreEvent]);

  const accountsWithStatus = useMemo<CloudAccountWithConnectionState[] | undefined>(() => {
    return cloudAccountData?.data.map((cloudAccount) => ({
      ...cloudAccount,
      state: connectionStatusData.data?.[cloudAccount.accountID],
    }));
  }, [cloudAccountData?.data, connectionStatusData]);

  const currentCloudAccount = accountsWithStatus?.find(
    (cloudAccount) => selectedAccount?.accountID === cloudAccount.accountID
  );

  const providersListColumns = useMemo<SimpleTableColumnDef<SvcRisksApi.Schemas.CloudProvider>[]>(
    () => [
      {
        header: t('cloudProvider'),
        accessorKey: 'cloudProvider',
        size: 170,
        cell: ({ value: cloudProvider }) => {
          if (cloudProvider === 'All') {
            return <Text>{t('all')}</Text>;
          } else {
            return (
              <Link
                href={`/#/app/server-cloud/cloud-account-management?provider=${cloudProvider}`}
                _visited={{ color: 'blue:40' }} // to preserve the design color after the link has been clicked
                onClick={(e) => {
                  e.preventDefault();
                  window.top?.APP.$router.push({
                    path: '/app/server-cloud/cloud-account-management',
                  });
                }}
              >
                {cloudProvider}
              </Link>
            );
          }
        },
      },
      {
        header: t('accounts'),
        accessorKey: 'numberOfCloudAccounts',
        size: 130,
      },
    ],
    [t]
  );

  const accountListColumns = useMemo<ColumnDef<CloudAccountWithConnectionState>[]>(
    () => [
      {
        header: t('accountAlias'),
        accessorKey: 'accountAlias',
        customSize: 'auto',
        cell: ({ getValue }) => <TruncateWithTooltip text={getValue() as string} />,
        sortingFn: 'alphanumeric',
      },
      {
        header: t('accountID'),
        accessorKey: 'accountID',
        customSize: 'auto',
        sortingFn: 'alphanumeric',
      },
      ...(allProviders
        ? [
            {
              header: t('riskGraph.assetsDetailsDrawer.Provider'),
              accessorKey: 'provider',
              customSize: 130,
            },
          ]
        : []),
      {
        header: t('highestScore'),
        accessorKey: 'highestScore',
        customSize: 'auto',
        cell: ({ row }) => {
          if ('regions' in row.original) {
            return (
              <Text color={getAccountsRiskColor(row.original.highestScore)}>
                {row.original.highestScore}
              </Text>
            );
          }
        },
      },
      {
        header: t('riskyAssetCount'),
        accessorKey: 'riskyAssetCount',
        customSize: 'auto',
      },
      {
        header: t('regions'),
        accessorKey: 'regions',
        accessorFn: (row) => {
          if ('regions' in row) {
            return row.regions.length === 1 ? row.regions[0] : row.regions.length;
          }
        },
        customSize: 'auto',
        cell: ({ row }) => {
          if ('regions' in row.original) {
            return row.original.regions.length === 1
              ? row.original.regions[0]
              : row.original.regions.length;
          }
        },
        sortingFn: 'alphanumeric',
      },
      {
        header: () => (
          <Flex>
            {t('status')}
            {connectedAccountDataIsLoading && (
              <>
                <Space width="2x" />
                <Spinner size="xs" />
              </>
            )}
          </Flex>
        ),
        accessorKey: 'state',
        customSize: 'auto',
        cell: ({ row }) => {
          return (
            <Flex data-id="accounts-table-connection-status" alignItems="center">
              {connectedAccountDataIsLoading ? (
                '-'
              ) : (
                <>
                  <Badge
                    minWidth="8px"
                    variant="dot"
                    backgroundColor={
                      !row.original.state
                        ? 'gray:40'
                        : row.original.state === 'outdated' || row.original.state === 'managed'
                        ? 'green:40'
                        : 'red:50'
                    }
                    boxShadow="none"
                    border="none"
                    marginRight="2x"
                  />
                  <Text>
                    {row.original.state === 'failed'
                      ? t('disconnected')
                      : row.original.state === 'outdated'
                      ? t('connected')
                      : row.original.state === 'managed'
                      ? t('connected')
                      : t('notAvailable')}
                  </Text>
                </>
              )}
            </Flex>
          );
        },
      },
    ],
    [t, allProviders, connectedAccountDataIsLoading]
  );

  const defaultProviderSelection = cloudProvidersData
    ? cloudProvidersData.data.find((provider) => provider.cloudProvider === selectedProvider) ||
      (cloudProvidersData.data.length > 0 ? cloudProvidersData.data[0] : undefined)
    : undefined;

  const defaultAccountSelection = accountsWithStatus
    ? accountsWithStatus.find((account) => account.accountID === selectedAccount?.accountID) ||
      undefined
    : undefined;

  return (
    <Flex height="100%" color="white:secondary" paddingBottom="2x">
      <Tabs
        display="flex"
        flexDirection="column"
        height="100%"
        flex="0 0 335px"
        borderRight="1px dashed"
        borderRightColor={colorStyle.divider}
        paddingRight="3x"
      >
        <TabList>
          <Tab flex="1">{t('accounts')}</Tab>
        </TabList>
        <TabPanels paddingTop="2x" flex="1">
          <TabPanel height="100%">
            {cloudProviderDataIsLoading ? (
              <Flex height="100%" justifyContent="center" alignItems="center">
                <Spinner />
              </Flex>
            ) : (
              cloudProvidersData && (
                <SimpleTable
                  id="providers-table"
                  data={cloudProvidersData.data}
                  columns={providersListColumns}
                  onSelectRow={(row) => {
                    if (!row) return;
                    if (row.cloudProvider !== selectedProvider) {
                      dispatchGlobalStoreEvent({
                        type: 'CHANGE_CLOUD_PROVIDER',
                        payload: row.cloudProvider as SvcRisksApi.Parameters.Provider,
                      });
                    }
                  }}
                  defaultRowSelection={defaultProviderSelection}
                />
              )
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Stack flex="1" paddingLeft="3x">
        {cloudAccountDataIsLoading ? (
          <Flex flex="1" justifyContent="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : (
          accountsWithStatus && (
            <Box flex="1">
              <Table
                id="accounts-table"
                key={selectedProvider}
                columns={accountListColumns}
                selectedRow={currentCloudAccount}
                data={accountsWithStatus}
                showSearch={true}
                onSelectRow={(row) => {
                  if (!row) return;
                  // Check if using updated accounts API
                  // Prevent onSelectRow from triggering when the same row is clicked again.
                  if (row.accountID !== selectedAccount?.accountID) {
                    dispatchGlobalStoreEvent({
                      type: 'CHANGE_ACCOUNT',
                      payload: {
                        accountProvider: row.provider,
                        accountID: row.accountID,
                        accountAlias: row.accountAlias,
                        accountRegion: row.regions[0],
                      },
                    });
                  }
                }}
                SearchComponent={AccountSearchInput}
                emptySearchDisplay={
                  <Grid height="100%" alignItems="center" justifyContent="center">
                    <Trans i18nKey="noDataToDisplay" />
                  </Grid>
                }
                defaultRowSelection={defaultAccountSelection}
              />
            </Box>
          )
        )}
      </Stack>
    </Flex>
  );
};

CloudAssetsContainer.displayName = 'CloudAssetsContainer';
export default CloudAssetsContainer;
