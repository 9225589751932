import { useMemo, useCallback } from 'react';
import { Box, Flex, Text } from '@tonic-ui/react';
import { useTranslation } from 'react-i18next';
import InfoPopTip from './InfoPopTip';

type SignificantTagsProps = {
  absoluteTags?: Record<string, string | string[]>;
  highTags?: Record<string, string | string[]>;
  lowTags?: Record<string, string | string[]>;
  assetID: string;
};

const SignificantTags = ({
  absoluteTags = {},
  highTags = {},
  lowTags = {},
}: SignificantTagsProps) => {
  // i18n
  const { t } = useTranslation();
  // cached states
  const hasData = useMemo(() => {
    return (
      Object.keys(absoluteTags || {}).length > 0 ||
      Object.keys(highTags || {}).length > 0 ||
      Object.keys(lowTags || {}).length > 0
    );
  }, [absoluteTags, highTags, lowTags]);
  // callbacks
  const getTagValue = useCallback((value: string | any[]) => {
    if (Array.isArray(value) && value.length > 0 && typeof value[0] === 'string') {
      return value.join(', ');
    } else if (typeof value === 'string') {
      return value;
    } else {
      return '';
    }
  }, []);

  return (
    <Box marginTop="4x">
      <>
        <Box marginBottom="3x" marginTop="1x">
          {t('riskGraph.assetsDetailsDrawer.descriptions.tags')}
        </Box>
        <Flex
          marginTop="1x"
          color="white:secondary"
          fontSize="xs"
          lineHeight="xs"
          flexWrap="wrap"
          columnGap="3x"
        >
          <Flex gap="2x" align="center">
            <Text background="blue:60" borderRadius="50%" width="2x" height="2x" />
            <Text flexShrink="0" whiteSpace="nowrap">
              {t('riskGraph.assetsDetailsDrawer.labels.absSign')}
            </Text>
            <InfoPopTip
              width="238px"
              placement="bottom-start"
              label={t('riskGraph.assetsDetailsDrawer.tooltips.absSign')}
            />
          </Flex>
          <Flex gap="2x" align="center">
            <Text background="#95B7FCCC" borderRadius="50%" width="2x" height="2x" />
            <Text flexShrink="0" whiteSpace="nowrap">
              {t('riskGraph.assetsDetailsDrawer.labels.highImpactCriticality')}
            </Text>
          </Flex>
          <Flex gap="2x" align="center">
            <Text background="gray:40" borderRadius="50%" width="2x" height="2x" />
            <Text flexShrink="0" whiteSpace="nowrap">
              {t('riskGraph.assetsDetailsDrawer.labels.lowImpactCriticality')}
            </Text>
          </Flex>
        </Flex>
      </>
      {hasData ? (
        <Flex marginY="4x" flexWrap="wrap" gap="2x">
          {Object.keys(absoluteTags || {}).length > 0 &&
            Object.keys(absoluteTags).map((tagKey) => (
              <Box
                background="blue:60"
                borderRadius="sm"
                paddingX="1x"
                color="white:primary"
                key={tagKey}
              >
                <Text fontWeight="bold" marginRight="1x" display="inline-block">
                  {tagKey}:
                </Text>
                <Text display="inline-block">{getTagValue(absoluteTags[tagKey])}</Text>
              </Box>
            ))}
          {Object.keys(highTags || {}).length > 0 &&
            Object.keys(highTags).map((tagKey) => (
              <Box
                background="#95B7FCCC"
                borderRadius="sm"
                paddingX="1x"
                color="black:primary"
                key={tagKey}
              >
                <Text fontWeight="bold" marginRight="1x" display="inline-block">
                  {tagKey}:
                </Text>
                <Text display="inline-block">{getTagValue(highTags[tagKey])}</Text>
              </Box>
            ))}
          {Object.keys(lowTags || {}).length > 0 &&
            Object.keys(lowTags).map((tagKey) => (
              <Box background="gray:60" borderRadius="sm" paddingX="1x" key={tagKey}>
                <Text fontWeight="bold" marginRight="1x" display="inline-block">
                  {tagKey}:
                </Text>
                <Text display="inline-block">{getTagValue(lowTags[tagKey])}</Text>
              </Box>
            ))}
        </Flex>
      ) : (
        <Box textAlign="center" marginY="11x" color="white:secondary">
          {t('riskGraph.assetsDetailsDrawer.noSignificantTags')}
        </Box>
      )}
    </Box>
  );
};
export default SignificantTags;
