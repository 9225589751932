import { Flex, Text } from '@tonic-ui/react';
import { useTranslation } from 'react-i18next';

const GraphLoaderOverlay = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex data-id="graph-overlay-loading" height="100%" alignItems="center" justifyContent="center">
      <Text size="lg" color="white:secondary">
        {t('riskGraph.loader.message')}
      </Text>
    </Flex>
  );
};

GraphLoaderOverlay.displayName = 'GraphLoaderOverlay';
export default GraphLoaderOverlay;
