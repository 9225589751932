import { PropsWithChildren } from 'react';
import { Menu, MenuDivider, MenuItem, Text, Tooltip, useTheme, Flex } from '@tonic-ui/react';
import { Trans } from 'react-i18next';

import { tooltipBGColorCode } from '../helpers';
import { getRiskColor } from '../../helpers';

const NodeTooltipNumberedItem = (props: {
  labelTransKey: string;
  value: number;
  valueTextColor?: string;
}) => {
  const { labelTransKey, value, valueTextColor = 'white:emphasis' } = props;

  return (
    <Flex width="100%" alignItems="center" justifyContent="space-between">
      <Trans i18nKey={labelTransKey} />
      <Text paddingLeft="2x" color={valueTextColor}>
        {value}
      </Text>
    </Flex>
  );
};

type GroupPopoverProps = {
  isShown: boolean;
  groupLabel: string;
  highestRiskScore: number | undefined;
  uniqueAssetTypesCount: number;
};

const GroupPopover = (props: PropsWithChildren<GroupPopoverProps>) => {
  const { isShown, groupLabel, highestRiskScore, uniqueAssetTypesCount, children } = props;
  const theme = useTheme();
  const { colors } = theme;

  return (
    <Tooltip
      isOpen={isShown}
      background={colors[tooltipBGColorCode]}
      padding={0}
      label={
        <Menu display="block" padding="0">
          <MenuItem color="white:primary" fontWeight="semibold">
            <Text>{groupLabel}</Text>
          </MenuItem>
          <MenuDivider marginY="0" marginX="2x" />
          {highestRiskScore !== undefined && (
            <MenuItem color="white:secondary">
              <NodeTooltipNumberedItem
                labelTransKey="riskGraph.groupNodeTooltip.highestRiskScore"
                value={highestRiskScore}
                valueTextColor={getRiskColor(highestRiskScore)}
              />
            </MenuItem>
          )}
          {!!uniqueAssetTypesCount && (
            <MenuItem color="white:secondary">
              <NodeTooltipNumberedItem
                labelTransKey="riskGraph.groupNodeTooltip.assetTypes"
                value={uniqueAssetTypesCount}
              />
            </MenuItem>
          )}
        </Menu>
      }
      placement="right"
      PopperProps={{ usePortal: true, zIndex: 10 }}
    >
      {children}
    </Tooltip>
  );
};

GroupPopover.displayName = 'GroupPopover';
export default GroupPopover;
