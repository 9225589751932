import { useState, useMemo, PropsWithChildren } from 'react';
import { CloudAssetsGraphContext } from './useCloudAssetsGraphContext';

const CloudAssetsGraphProvider = (props: PropsWithChildren) => {
  const [contextMenuNodeId, setContextMenuNodeId] = useState<number | undefined>();
  const [activeGroupId, setActiveGroupId] = useState<number | undefined>();
  const [securityGroupNodeId, setSecurityGroupNodeId] = useState<number | undefined>();
  const [securityGroupNodeIsActive, setSecurityGroupNodeIsActive] = useState<boolean>();
  const [activeGroupParentId, setActiveGroupParentId] = useState<number | undefined>();
  const [hoveredGroupId, setHoveredGroupId] = useState<number | undefined>();
  const [activeSecurityGroupAssetCount, setActiveSecurityGroupAssetCount] = useState<
    number | undefined
  >();
  const [activeSecondaryLinks, setActiveSecondaryLinks] = useState<SvcRisksApi.Schemas.Link[]>();
  const [activePrimaryLinks, setActivePrimaryLinks] = useState<SvcRisksApi.Schemas.Link[]>();
  const [activeSGLinks, setActiveSGLinks] = useState<SvcRisksApi.Schemas.Link[]>();

  const context = useMemo(
    () => ({
      contextMenuNodeId,
      activeGroupId,
      activeGroupParentId,
      securityGroupNodeIsActive,
      securityGroupNodeId: securityGroupNodeId,
      hoveredGroupId,
      activeSecurityGroupAssetCount,
      activeSecondaryLinks,
      activePrimaryLinks,
      activeSGLinks,
      onContextMenuNodeChange: setContextMenuNodeId,
      onActiveGroupIdChange: setActiveGroupId,
      onSecurityGroupNodeIdChange: setSecurityGroupNodeId,
      onActiveGroupParentIdChange: setActiveGroupParentId,
      onHoveredGroupIdChange: setHoveredGroupId,
      onSecurityGroupNodeIsActiveChange: setSecurityGroupNodeIsActive,
      onActiveSecurityGroupAssetCountChange: setActiveSecurityGroupAssetCount,
      onActiveSecondaryLinksChange: setActiveSecondaryLinks,
      onActivePrimaryLinksChange: setActivePrimaryLinks,
      onActiveSGLinksChange: setActiveSGLinks,
    }),
    [
      contextMenuNodeId,
      activeGroupId,
      activeGroupParentId,
      securityGroupNodeIsActive,
      securityGroupNodeId,
      hoveredGroupId,
      activeSecurityGroupAssetCount,
      activeSecondaryLinks,
      activePrimaryLinks,
      activeSGLinks,
      setSecurityGroupNodeIsActive,
    ]
  );

  return <CloudAssetsGraphContext.Provider value={context} {...props} />;
};

export default CloudAssetsGraphProvider;
