// Wrappers around pendo methods to avoid errors when pendo is blocked by user scripts or tracking is disabled
export const PendoGuides = {
  // ASD - cloud asset graph view feedback (https://app.eu.pendo.io/s/5715319363600384/guides/crspKjJ47U5pNggeGG3QGb2RDJM)
  FeedbackForm: 'crspKjJ47U5pNggeGG3QGb2RDJM',
};

export const isPendoReady = () => {
  // Check if tracking is allowed(_TRACKABLE===true) and that pendo was successfully loaded (wasn't blocked by user scripts)
  try {
    return !!(window.parent._TRACKABLE && window.pendo?.isReady());
  } catch {
    console.error('Error checking if pendo is ready');
    return false;
  }
};

export const getPendoGuide = (guideId: string) => {
  try {
    if (!isPendoReady()) return;
    return window.pendo.findGuideById(guideId);
  } catch {
    console.error(`Error using pendo to find guide by id: ${guideId}`);
  }
};

export const pendoTrack = (eventName: string, params?: Record<string, unknown>) => {
  try {
    if (!isPendoReady()) return;
    window.pendo.track(eventName, params);
  } catch {
    console.error(`Error using pendo to track event of type: ${eventName}`);
  }
};

export const encodeDataTrackContent = (content: unknown) => {
  if (typeof content === 'undefined') {
    return undefined;
  }
  let result: string | undefined;
  try {
    result = btoa(encodeURIComponent(JSON.stringify(content)));
  } catch {}
  return result;
};
