import { Component, ComponentType, PropsWithChildren } from 'react';

class ErrorBoundary extends Component<
  PropsWithChildren<{
    ErrorComponent: ComponentType<{ error: unknown }>;
  }>,
  { hasError: boolean; error: unknown }
> {
  displayName = 'ErrorBoundary';

  constructor(props: { ErrorComponent: ComponentType<{ error: unknown }> }) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      const ErrorComponent = this.props.ErrorComponent;
      return <ErrorComponent error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
