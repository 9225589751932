import { Box, Tooltip, Truncate, TooltipProps } from '@tonic-ui/react';
import { useEventListener, useToggle } from '@tonic-ui/react-hooks';
import { useRef, useCallback, MouseEventHandler, memo } from 'react';

interface TruncateWithTooltipProps {
  text: string;
  width?: string | number;
  maxTooltipWidth?: string | number;
  tooltipStyles?: Partial<TooltipProps>;
}

const TruncateWithTooltip = memo((props: TruncateWithTooltipProps) => {
  const { text, width = '100%', maxTooltipWidth = 480, tooltipStyles } = props;
  const textRef = useRef(null);
  const [isOpen, toggleIsOpen] = useToggle();
  useEventListener(
    () => textRef.current,
    'mouseenter', // It can be either 'mouseenter' or 'pointerenter'
    useCallback<MouseEventHandler>(
      (event) => {
        const isOverflowing = event.currentTarget?.scrollWidth > event.currentTarget?.clientWidth;
        isOverflowing && toggleIsOpen(true);
      },
      [toggleIsOpen]
    )
  );
  useEventListener(
    () => textRef.current,
    'mouseleave', // It can be either 'mouseleave' or 'pointerleave'
    useCallback<MouseEventHandler>(() => {
      toggleIsOpen(false);
    }, [toggleIsOpen])
  );

  return (
    <Box width={width} minWidth="0">
      <Tooltip
        isOpen={isOpen}
        label={text}
        maxWidth={maxTooltipWidth}
        background="gray:80"
        color="white:secondary"
        wordBreak="break-all"
        whiteSpace="normal"
        {...tooltipStyles}
      >
        <Truncate ref={textRef}>{text}</Truncate>
      </Tooltip>
    </Box>
  );
});

TruncateWithTooltip.displayName = 'TruncateWithTooltip';

export default TruncateWithTooltip;
