import { useContext } from 'react';
import { Flex, Text, Icon, Box, Divider, Tooltip } from '@tonic-ui/react';
import { InfoOIcon } from '@tonic-ui/react-icons';
import { Trans } from 'react-i18next';
import { requiredProvidersServiceNamesForGraph } from '../helpers';
import { AppContext } from '../../../store/store';

interface GraphEmptyOverlayProps {
  emptyOverlayType:
    | 'noAccountSelected'
    | 'accountHasNoContent'
    | 'accountHasNoProcessedDataForRiskView'
    | 'accountHasNoGraphData'
    | 'accountHasMalformedGraphData'
    | 'graphAPIError'
    | 'accountHasNoProcessedData';
}

const GraphEmptyOverlay = (props: GraphEmptyOverlayProps): JSX.Element => {
  const { emptyOverlayType } = props;
  const [{ selectedAccount }] = useContext(AppContext);

  const overlayTransKey = {
    noAccountSelected: 'riskGraph.empty.noAccountSelected',
    accountHasNoContent: 'riskGraph.empty.accountHasNoContent',
    accountHasNoProcessedDataForRiskView: 'riskGraph.empty.accountHasNoProcessedDataForRiskView',
    accountHasNoGraphData: 'riskGraph.empty.accountHasNoGraphData',
    accountHasMalformedGraphData: 'riskGraph.empty.accountHasMalformedGraphData',
    accountHasNoProcessedData: 'riskGraph.empty.accountHasNoProcessedData',
    graphAPIError: 'riskGraph.error.graphAPIError',
  };

  const requiredServiceNamesForGraph =
    (selectedAccount?.accountProvider &&
      requiredProvidersServiceNamesForGraph[selectedAccount?.accountProvider]) ||
    [];

  const TooltipContent = (
    <Box>
      <Text color="white:emphasis" marginBottom="2x" marginTop="2x">
        <Trans i18nKey="supportedResources" />
      </Text>
      <Divider backgroundColor="gray:70" />
      <Box as="ul" paddingLeft="6x">
        {requiredServiceNamesForGraph?.map((serviceName) => {
          return (
            <Box
              key={serviceName}
              as="li"
              display="list-item"
              color="white:primary"
              marginBottom="2x"
            >
              <Text>{serviceName}</Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <Flex
      data-id={`graph-empty-overlay-${emptyOverlayType}`}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Text textAlign="center" color="white:secondary" size="sm">
        <Trans
          i18nKey={overlayTransKey[emptyOverlayType]}
          values={{
            accountID: selectedAccount?.accountID,
            region: selectedAccount?.accountRegion,
          }}
        />
      </Text>
      {emptyOverlayType === 'accountHasNoGraphData' && (
        <Text textAlign="left" color="white:secondary" size="sm" marginTop="2x">
          <Trans i18nKey="riskGraph.empty.accountHasNoGraphDataDetails" />
          <Tooltip
            placement="right-end"
            backgroundColor="gray:80"
            offset={[10, 10]}
            label={TooltipContent}
          >
            <Icon as={InfoOIcon} color="blue:50" marginLeft="1x" />
          </Tooltip>
        </Text>
      )}
    </Flex>
  );
};

GraphEmptyOverlay.displayName = 'GraphEmptyOverlay';
export default GraphEmptyOverlay;
