import { AxiosRequestConfig } from 'axios';
import { axios } from './instance';

export const getConnectionStatus = async (
  provider?: SvcRisksApi.Parameters.Provider,
  requestConfig?: AxiosRequestConfig
) => {
  switch (provider) {
    case 'AWS':
      return axios.get<ConnectionStatusResponse>('/public/cam/api/v1/awsAccounts', requestConfig);
    case 'Azure':
      return axios.get<ConnectionStatusResponse>(
        '/public/cam/api/ui/azureSubscriptions',
        requestConfig
      );
    case 'GCP':
      return axios.get<ConnectionStatusResponse>('/public/cam/api/ui/gcpProjects', requestConfig);
  }
};
