import { useEffect, useMemo, useState, useRef, useLayoutEffect, useContext } from 'react';
import { select, zoom, zoomIdentity } from 'd3';
import { Text, Flex, Truncate, Box, useTheme } from '@tonic-ui/react';

import { CloudAssetsGraphLegend } from './VisualizationComponents/CloudAssetsGraphLegend';
import { useCloudAssetsGraphContext } from './useCloudAssetsGraphContext';
import CloudAssetsGraphZoomButtons from './VisualizationComponents/CloudAssetsGraphZoomButtons';
import CloudAssetsDetailsDrawer from './GraphComponents/CloudAssetsDetailsDrawer';
import CloudAssetsGraphLink from './VisualizationComponents/CloudAssetsGraphLink';
import CloudAssetsGraphNodeGroup from './VisualizationComponents/CloudAssetsGraphNodeGroup';
import CloudAssetsGraphNode from './VisualizationComponents/CloudAssetsGraphNode';
import GraphEmptyOverlay from './GraphOverlays/CloudAssetsGraphEmptyOverlay';

import {
  flipGridCoordinates,
  getStrokeWidthByZoomValue,
  MIN_ZOOM_VALUE,
  MAX_ZOOM_VALUE,
  graphBGColorCode,
  getLevelGap,
  augmentDataForGridGraphViz,
} from './helpers';
import {
  augmentLinksDataForGridGraphViz,
  getBasicLinksFromAugmentedLinkList,
} from './VisualizationComponents/linkHelpers';
import { assetsDrawerWidth, securityGroupServiceTypes } from '../helpers';
import { AppContext } from '../../store/store';
import { Flags } from '../../utils/feature-flags';

type CloudAssetsGraphProps = {
  data: SvcRisksApi.Schemas.ListAssetGroupsByRegionResponse;
  dataType: 'riskView' | 'networkView';
  flipGraphOrientation: boolean;
};

const CloudAssetsGraph = (props: CloudAssetsGraphProps) => {
  const { data, dataType, flipGraphOrientation } = props;
  const theme = useTheme();
  const { colors } = theme;
  const [{ selectedAccount }] = useContext(AppContext);

  const {
    activeGroupId,
    activeGroupParentId,
    activePrimaryLinks,
    onActiveGroupIdChange,
    onActiveGroupParentIdChange,
    onActivePrimaryLinksChange,
    onActiveSecondaryLinksChange,
    onSecurityGroupNodeIdChange,
    onActiveSecurityGroupAssetCountChange,
    onSecurityGroupNodeIsActiveChange,
  } = useCloudAssetsGraphContext();

  const grid = data.grids.find((grid) =>
    dataType === 'riskView' ? grid.id === 'RiskFocusGrouping' : grid.id === 'Subnet'
  );

  const isPayloadMalformed = useRef<boolean>(false);
  const isEmpty = !grid || grid?.height === 0 || grid?.width === 0 || data.groups.length < 2;
  const gridSystem: GraphGrid = useMemo(() => {
    if (!grid || grid?.height === 0 || grid?.width === 0) {
      console.error('Subnet grid is either missing or has a width or length of 0');
      return [];
    }

    if (flipGraphOrientation) {
      return flipGridCoordinates(grid.grid, grid.height);
    } else {
      return grid.grid;
    }
  }, [grid, flipGraphOrientation]);

  const NODE_RADIUS = 30;
  const zoomStep = (MAX_ZOOM_VALUE - MIN_ZOOM_VALUE) / 6;

  const X_GAP = getLevelGap('x', grid?.width || 0, grid?.height || 0, flipGraphOrientation);
  const Y_GAP = getLevelGap('y', grid?.width || 0, grid?.height || 0, flipGraphOrientation);

  const svgElRef = useRef<SVGSVGElement | null>(null);
  const [d3zoomLevel, setd3zoomLevel] = useState<number>(0.3);
  const [d3translate, setd3translate] = useState<{ x: number; y: number } | undefined>();
  const d3zoomLevelUpdateTimer = useRef<any>();
  const [graphContentIsOutOfBoundaries, setGraphContentIsOutOfBoundaries] =
    useState<boolean>(false);

  const [nodes, setNodes] = useState<GraphNode[]>([]);
  const [primaryLinks, setPrimaryLinks] = useState<GraphLink[]>([]);
  const primaryLinksFiltered = useRef<SvcRisksApi.Schemas.Link[]>([]);
  const [secondaryLinks, setSecondaryLinks] = useState<GraphLink[]>([]);
  const secondaryLinksFiltered = useRef<SvcRisksApi.Schemas.Link[]>([]);
  const [securityGroupLinks, setSecurityGroupLinks] = useState<GraphLink[]>([]);
  const securityGroupLinksFiltered = useRef<SvcRisksApi.Schemas.Link[]>([]);
  const [graphContentHeight, setGraphContentHeight] = useState<number>(0);
  const [graphContentWidth, setGraphContentWidth] = useState<number>(0);
  const [graphContentStartXPos, setGraphContentStartXPos] = useState<number>(0);
  const [graphContentStartYPos, setGraphContentStartYPos] = useState<number>(0);

  // d3 zoom
  const zoomed = ({ transform }: { transform: any }) => {
    select('g.masterG').attr('transform', transform);
    if (d3zoomLevelUpdateTimer.current) clearTimeout(d3zoomLevelUpdateTimer.current);
    d3zoomLevelUpdateTimer.current = setTimeout(() => {
      setd3zoomLevel(transform.k);
      setd3translate({
        x: transform.x,
        y: transform.y,
      });
    }, 250);

    const masterGCoordinates = document.querySelector('g.masterG')?.getBoundingClientRect();
    const svgElRefCoordinates = svgElRef?.current?.getBoundingClientRect();

    if (svgElRefCoordinates && masterGCoordinates) {
      const isTooFarTop =
        masterGCoordinates.top - svgElRefCoordinates.top < -masterGCoordinates.height;
      const isTooFarBottom =
        masterGCoordinates.bottom - masterGCoordinates.height > svgElRefCoordinates.bottom;
      const isTooFarLeft = masterGCoordinates.left + masterGCoordinates.width < 0;
      const isTooFarRight = masterGCoordinates.left > svgElRefCoordinates.width;

      if (isTooFarTop || isTooFarBottom || isTooFarLeft || isTooFarRight) {
        setGraphContentIsOutOfBoundaries(true);
      } else {
        setGraphContentIsOutOfBoundaries(false);
      }
    }
  };

  const d3zoom: any = zoom().scaleExtent([MIN_ZOOM_VALUE, MAX_ZOOM_VALUE]).on('zoom', zoomed);
  const d3svgEl = select(svgElRef.current);
  const [{ isGraphFullscreen }] = useContext(AppContext);

  useLayoutEffect(() => {
    if (!isEmpty) {
      const augmentedGraphData = augmentDataForGridGraphViz(
        flipGraphOrientation,
        grid,
        gridSystem,
        data,
        X_GAP,
        Y_GAP,
        NODE_RADIUS
      );

      setNodes(augmentedGraphData.nodes);
      setGraphContentWidth(augmentedGraphData.graphContentWidth);
      setGraphContentHeight(augmentedGraphData.graphContentHeight);
      setGraphContentStartXPos(augmentedGraphData.graphContentStartXPos);
      setGraphContentStartYPos(augmentedGraphData.graphContentStartYPos);

      isPayloadMalformed.current =
        augmentedGraphData.isPayloadMalformed || augmentedGraphData.nodes.length < 2;

      const augSecurityGroupNode = augmentedGraphData.nodes.find((node) =>
        securityGroupServiceTypes.includes(node.serviceType)
      );

      // Augmenting links to allow for some better layout control.
      const d3PrimaryLinks = augmentLinksDataForGridGraphViz(
        data.links,
        augmentedGraphData.nodes,
        flipGraphOrientation,
        gridSystem
      );

      const filteredD3PrimaryLinks = augSecurityGroupNode
        ? d3PrimaryLinks.filter((d3link) => d3link.target.groupID !== augSecurityGroupNode.groupID)
        : d3PrimaryLinks;
      setPrimaryLinks(filteredD3PrimaryLinks);
      primaryLinksFiltered.current = getBasicLinksFromAugmentedLinkList(
        data.links,
        filteredD3PrimaryLinks
      );

      const childGroupsNodes = augmentedGraphData.nodes.flatMap((node) => node.childNodes ?? []);
      const d3SecondaryLinks = augmentLinksDataForGridGraphViz(
        data.links,
        childGroupsNodes,
        flipGraphOrientation,
        gridSystem
      );
      const filteredD3SecondaryLinks = augSecurityGroupNode
        ? d3SecondaryLinks.filter(
            (d3link) => d3link.source.groupID !== augSecurityGroupNode.groupID
          )
        : d3SecondaryLinks;
      setSecondaryLinks(filteredD3SecondaryLinks);
      secondaryLinksFiltered.current = getBasicLinksFromAugmentedLinkList(
        data.links,
        filteredD3SecondaryLinks
      );

      if (augSecurityGroupNode) {
        onSecurityGroupNodeIdChange(augSecurityGroupNode.groupID);
        const augNodes = [augSecurityGroupNode]
          .concat(augmentedGraphData.nodes)
          .concat(childGroupsNodes);
        const securityGroupLinks = data.links.filter(
          (link) =>
            link.sourceGroupID === augSecurityGroupNode.groupID ||
            link.targetGroupID === augSecurityGroupNode.groupID
        );
        const d3SecurityGroupLinks = augmentLinksDataForGridGraphViz(
          securityGroupLinks,
          augNodes,
          flipGraphOrientation,
          gridSystem,
          true
        );
        setSecurityGroupLinks(d3SecurityGroupLinks);
        securityGroupLinksFiltered.current = getBasicLinksFromAugmentedLinkList(
          data.links,
          d3SecurityGroupLinks
        );
      }

      if (isGraphFullscreen || !isPayloadMalformed.current) {
        defaultZoom(augmentedGraphData.graphContentWidth, augmentedGraphData.graphContentHeight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGraphFullscreen, gridSystem, graphContentWidth]);

  const defaultZoom = (contentWidth: number, contentHeight: number) => {
    const svgRenderedWidth = svgElRef?.current?.clientWidth || 1300;
    const svgRenderedHeight = svgElRef?.current?.clientHeight || 800;

    d3svgEl.call(d3zoom);

    const graphContentArea = contentHeight * contentWidth;
    const containerArea = svgRenderedWidth * svgRenderedHeight;

    const initialScaleFactor = Math.sqrt(containerArea / graphContentArea);

    if (contentWidth > 0 && contentHeight > 0 && svgRenderedWidth > 0 && svgRenderedHeight > 0) {
      const dataBasedScale = initialScaleFactor / 3;
      const INITIAL_SCALE =
        dataBasedScale < MIN_ZOOM_VALUE + 0.02
          ? MIN_ZOOM_VALUE + 0.02
          : dataBasedScale > MAX_ZOOM_VALUE - 0.25
          ? MAX_ZOOM_VALUE - 0.25
          : dataBasedScale;

      // Calculates x & y of the center point by adding half the width/height of the graph content
      const middleX = graphContentStartXPos + contentWidth / 2;
      const middleY = graphContentStartYPos + contentHeight / 2;

      d3svgEl.call(
        d3zoom.transform,
        zoomIdentity
          .translate(
            svgRenderedWidth / 2 - middleX * INITIAL_SCALE,
            svgRenderedHeight / 2 - middleY * INITIAL_SCALE
          )
          .scale(INITIAL_SCALE)
      );

      setd3zoomLevel(INITIAL_SCALE);
    }
  };

  const zoomOut = () => {
    const newZoomLevel =
      d3zoomLevel - zoomStep >= MIN_ZOOM_VALUE ? d3zoomLevel - zoomStep : MIN_ZOOM_VALUE;
    setd3zoomLevel(newZoomLevel);
    d3svgEl.transition().duration(500).call(d3zoom.scaleTo, newZoomLevel);
  };

  const zoomIn = () => {
    const newZoomLevel =
      d3zoomLevel + zoomStep <= MAX_ZOOM_VALUE ? d3zoomLevel + zoomStep : MAX_ZOOM_VALUE;
    setd3zoomLevel(newZoomLevel);
    d3svgEl.transition().duration(500).call(d3zoom.scaleTo, newZoomLevel);
  };

  // STATE FOR CHILD COMPONENTS.
  const [isGroupAssetsListDrawerOpened, setIsGroupAssetsListDrawerOpened] =
    useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [selectedGroup, setSelectedGroup] = useState<any>();

  useEffect(() => {
    if (!selectedGroupId) {
      setSelectedGroup(undefined);
      setIsGroupAssetsListDrawerOpened(false);
    } else {
      const group = data.groups.find((group) => group.groupID === selectedGroupId);
      const allChildNodes = nodes.flatMap((node) => node.childNodes ?? []);

      const currentNode =
        nodes.find((node) => node.groupID === selectedGroupId) ||
        allChildNodes.find((childNode) => childNode.groupID === selectedGroupId);

      const remainingXSpace = svgElRef?.current?.clientWidth
        ? svgElRef?.current?.clientWidth - assetsDrawerWidth
        : 0;
      const remainingYSpace = svgElRef?.current?.clientHeight || 0;

      if (group?.numberOfAssets) {
        if (currentNode?.x && currentNode?.y && !isGroupAssetsListDrawerOpened) {
          d3svgEl
            .transition()
            .duration(500)
            .call(
              d3zoom.transform,
              zoomIdentity
                .translate(
                  0 - currentNode.x * d3zoomLevel + remainingXSpace / 2,
                  0 - currentNode.y * d3zoomLevel + remainingYSpace / 2
                )
                .scale(d3zoomLevel)
            );
        }

        setSelectedGroup(group);
        setIsGroupAssetsListDrawerOpened(true);
      } else {
        setSelectedGroup(undefined);
        setIsGroupAssetsListDrawerOpened(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId]);

  const onAssetsDetailsDrawerClose = () => {
    setIsGroupAssetsListDrawerOpened(false);
    setSelectedGroupId(undefined);
    setSelectedGroup(undefined);
  };

  useEffect(() => {
    // RESET GRAPH SELECTION EVENT HANDLING.
    const handleEscKeyPress = (event: any) => {
      if (event.key === 'Escape') {
        onActiveGroupIdChange(undefined);
        onActiveGroupParentIdChange(undefined);
        onActivePrimaryLinksChange([]);
        onActiveSecondaryLinksChange();
        setIsGroupAssetsListDrawerOpened(false);
        onSecurityGroupNodeIsActiveChange(false);
        onActiveSecurityGroupAssetCountChange(undefined);
      }
    };

    window.addEventListener('keydown', handleEscKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscKeyPress);
    };
  }, [
    onActiveGroupIdChange,
    onActiveGroupParentIdChange,
    onActivePrimaryLinksChange,
    onActiveSecondaryLinksChange,
    onSecurityGroupNodeIsActiveChange,
    onActiveSecurityGroupAssetCountChange,
  ]);

  // GRAPH COMPONENTS
  const nodeComponents = useMemo(() => {
    return nodes.map((node: GraphNode) => {
      if (node.childNodes?.length) {
        return (
          <CloudAssetsGraphNodeGroup
            x={node.x}
            y={node.y}
            r={NODE_RADIUS}
            group={node}
            nodes={node.childNodes}
            key={node.groupID}
            onGroupNodeClick={setSelectedGroupId}
            zoomLevel={d3zoomLevel}
            secondaryLinkList={secondaryLinksFiltered.current}
            primaryLinkList={primaryLinksFiltered.current}
            securityGroupLinkList={securityGroupLinksFiltered.current}
          />
        );
      }
      return (
        <CloudAssetsGraphNode
          x={node.x}
          y={node.y}
          r={NODE_RADIUS}
          text={node.groupLabel}
          serviceType={node.serviceType}
          key={node.groupID}
          riskiestAssetScore={node.riskiestAssetScore}
          totalAssets={node.numberOfAssets}
          onGroupNodeClick={setSelectedGroupId}
          groupId={node.groupID}
          flipGraphOrientation={flipGraphOrientation}
          zoomLevel={d3zoomLevel}
          securityGroupsCount={node.numberOfSecurityGroups}
          secondaryLinkList={secondaryLinksFiltered.current}
          primaryLinkList={primaryLinksFiltered.current}
          securityGroupLinkList={securityGroupLinksFiltered.current}
        />
      );
    });
  }, [nodes, NODE_RADIUS, flipGraphOrientation, d3zoomLevel]);

  const linkComponents = useMemo(() => {
    if (!!d3translate && primaryLinks) {
      // Set openports type links last in links list to make sure they are rendered last.
      const { openPortsLinks, nonOpenPortsLinks } = primaryLinks.reduce<{
        openPortsLinks: GraphLink[];
        nonOpenPortsLinks: GraphLink[];
      }>(
        (acc, primaryLink) => {
          if (primaryLink.linkType === 'OPENACCESS') {
            acc.openPortsLinks.push(primaryLink);
          } else {
            acc.nonOpenPortsLinks.push(primaryLink);
          }
          return acc;
        },
        { openPortsLinks: [], nonOpenPortsLinks: [] }
      );
      let orderedPrimaryLinks = nonOpenPortsLinks.concat(openPortsLinks);

      if (!!activePrimaryLinks?.length && Flags.edgesActiveOrdering) {
        const activeLinkSet = new Set(
          activePrimaryLinks?.map(
            (activePrimaryLink) =>
              `${activePrimaryLink.sourceGroupID}-${activePrimaryLink.targetGroupID}`
          )
        );
        const [nonActiveLinks, activeLinks] = orderedPrimaryLinks.reduce<
          [nonActiveLinks: GraphLink[], activeLinks: GraphLink[]]
        >(
          ([nonActive, active], orderedPrimaryLink) => {
            const key = `${orderedPrimaryLink.source.groupID}-${orderedPrimaryLink.target.groupID}`;
            if (activeLinkSet.has(key)) {
              active.push(orderedPrimaryLink);
            } else {
              nonActive.push(orderedPrimaryLink);
            }
            return [nonActive, active];
          },
          [[], []]
        );
        orderedPrimaryLinks = nonActiveLinks.concat(activeLinks);
      }

      return orderedPrimaryLinks.map((link: GraphLink, index: number) => {
        return (
          <CloudAssetsGraphLink
            graphOrientation={flipGraphOrientation ? 'vertical' : 'horizontal'}
            key={index}
            strokeWidth={getStrokeWidthByZoomValue(d3zoomLevel)}
            link={link}
            primaryLinkList={primaryLinksFiltered.current}
            grid={grid?.grid ?? []}
            X_GAP={X_GAP}
            Y_GAP={Y_GAP}
            zoomLevel={d3zoomLevel}
            nodeRadius={NODE_RADIUS}
          />
        );
      });
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryLinks, flipGraphOrientation, d3zoomLevel, d3translate, activePrimaryLinks]);

  const secondaryLinkComponents = useMemo(() => {
    return secondaryLinks.map((link: GraphLink, index: number) => {
      return (
        <CloudAssetsGraphLink
          graphOrientation={flipGraphOrientation ? 'vertical' : 'horizontal'}
          key={index}
          strokeWidth={getStrokeWidthByZoomValue(d3zoomLevel)}
          link={link}
          primaryLinkList={primaryLinksFiltered.current}
          secondaryLinkList={secondaryLinksFiltered.current}
          isLinkSecondary={true}
          X_GAP={X_GAP}
          Y_GAP={Y_GAP}
          grid={grid?.grid ?? []}
          zoomLevel={d3zoomLevel}
          nodeRadius={NODE_RADIUS}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryLinks, flipGraphOrientation, d3zoomLevel, d3translate]);

  const securityGroupLinkComponents = useMemo(() => {
    // TODO: Switch default primary link to blue and show the OPENACCESS info in the SG link.
    if (!activeGroupParentId && !activeGroupId) return [];
    return securityGroupLinks.map((link: GraphLink, index: number) => {
      return (
        <CloudAssetsGraphLink
          graphOrientation={flipGraphOrientation ? 'vertical' : 'horizontal'}
          key={index}
          strokeWidth={getStrokeWidthByZoomValue(d3zoomLevel)}
          link={link}
          primaryLinkList={primaryLinksFiltered.current}
          isLinkSecurityGroup={true}
          X_GAP={X_GAP}
          Y_GAP={Y_GAP}
          grid={grid?.grid ?? []}
          zoomLevel={d3zoomLevel}
          nodeRadius={NODE_RADIUS}
        />
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondaryLinks, flipGraphOrientation, d3zoomLevel, d3translate, activeGroupParentId]);

  const publicAccessElement = useMemo(() => {
    if (
      graphContentHeight === 0 ||
      graphContentWidth === 0 ||
      nodes.length === 0 ||
      gridSystem.length === 0
    ) {
      return <></>;
    }

    const textHeight = 26;

    const boxXPos = flipGraphOrientation
      ? graphContentStartXPos - X_GAP
      : graphContentStartXPos + X_GAP;
    const boxYPos = flipGraphOrientation
      ? graphContentStartYPos + Y_GAP
      : graphContentStartYPos - Y_GAP / 2;
    const boxWidth = flipGraphOrientation
      ? graphContentWidth + X_GAP * 2
      : graphContentWidth - X_GAP / 2;
    const boxTextOffset = 10;

    return (
      <g>
        <rect
          className="public-access-rect"
          x={boxXPos}
          y={boxYPos}
          width={boxWidth}
          height={
            flipGraphOrientation ? graphContentHeight - Y_GAP / 2 : graphContentHeight + Y_GAP
          }
          stroke={colors['gray:50']}
          strokeDasharray="5"
          strokeWidth={getStrokeWidthByZoomValue(d3zoomLevel)}
          rx="4"
          fill="transparent"
        ></rect>

        <foreignObject
          width={boxWidth - boxTextOffset * 2}
          height={textHeight}
          x={boxXPos + boxTextOffset}
          y={boxYPos - textHeight / 2}
        >
          <Flex
            color="white:secondary"
            fontSize="md"
            height={textHeight}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Truncate
              paddingX="1x"
              background={graphBGColorCode}
              maxWidth={`${boxWidth - boxTextOffset * 2 - 140}px`} // 140 -> approximate width of region text
            >
              {selectedAccount?.accountAlias}
            </Truncate>
            <Text background={graphBGColorCode} paddingRight="1x">
              / {selectedAccount?.accountRegion}
            </Text>
          </Flex>
        </foreignObject>
      </g>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphContentStartXPos, graphContentStartYPos, graphContentHeight, graphContentWidth, nodes]);

  return (
    <>
      {isEmpty ? (
        <GraphEmptyOverlay emptyOverlayType="accountHasNoGraphData" />
      ) : isPayloadMalformed.current ? (
        <GraphEmptyOverlay emptyOverlayType="accountHasMalformedGraphData" />
      ) : (
        <>
          <svg
            data-id="cloud-assets-graph-svg"
            id="cloudAssetsGraph"
            ref={svgElRef}
            width="100%"
            height="100%"
            style={{ cursor: 'grab', display: 'block' }}
          >
            <g className="masterG">
              {publicAccessElement}
              {/* Render links before nodes to ensure proper z-index ordering in SVGs */}
              {linkComponents}
              {nodeComponents}
              {secondaryLinkComponents}
              {securityGroupLinkComponents}
            </g>
          </svg>

          <CloudAssetsDetailsDrawer
            isOpen={isGroupAssetsListDrawerOpened}
            onClose={() => onAssetsDetailsDrawerClose()}
            selectedGroup={selectedGroup}
          />

          <Box position="fixed" bottom="4x" right="4x">
            <CloudAssetsGraphZoomButtons
              defaultZoom={() => defaultZoom(graphContentWidth, graphContentHeight)}
              zoomIn={zoomIn}
              zoomOut={zoomOut}
              graphContentIsOutOfBoundaries={graphContentIsOutOfBoundaries}
            />
            <CloudAssetsGraphLegend />
          </Box>
        </>
      )}
    </>
  );
};

CloudAssetsGraph.displayName = 'CloudAssetsGraph';
export default CloudAssetsGraph;
