import { useState, useCallback, RefObject } from 'react';

interface ResizeHook {
  height: number;
  handleMouseDown: (e: React.MouseEvent) => void;
}

export const useResize = (splitViewTopBoxRef: RefObject<HTMLElement>): ResizeHook => {
  const [height, setHeight] = useState(0);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const currentHeight = height !== 0 ? height : splitViewTopBoxRef?.current?.clientHeight || 0;
      const startY = e.clientY;
      const resize = (moveEvent: MouseEvent) => {
        const newHeight = currentHeight + (moveEvent.clientY - startY);
        setHeight(newHeight);
      };

      const stopResizing = () => {
        window.removeEventListener('mousemove', resize);
        window.removeEventListener('mouseup', stopResizing);
      };

      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResizing);
    },
    [height, splitViewTopBoxRef]
  );

  return { height, handleMouseDown };
};
