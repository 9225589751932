import { Stack, Tooltip, Text, Flex, Icon } from '@tonic-ui/react';
import { InfoOIcon } from '@tonic-ui/react-icons';
import { Trans } from 'react-i18next';
import { graphBGColorCode } from '../helpers';

import { ReactComponent as HighRisk } from '../../../assets/Icons/high-risk.svg';
import { ReactComponent as MediumRisk } from '../../../assets/Icons/medium-risk.svg';
import { ReactComponent as RegionLine } from '../../../assets/Icons/region-line.svg';
import { ReactComponent as GroupLine } from '../../../assets/Icons/group-line.svg';
import { ReactComponent as Connection } from '../../../assets/Icons/connection.svg';
import { ReactComponent as RiskyConnection } from '../../../assets/Icons/risky-connection.svg';
import { ReactComponent as ActiveConnection } from '../../../assets/Icons/active-connection.svg';

const LegendTooltipContent = () => {
  return (
    <Stack marginTop="2x" marginBottom="3x" spacing="4x">
      <Text color="white:emphasis" fontWeight="semibold">
        <Trans i18nKey="riskGraph.legend.title" />
      </Text>
      <Flex gap="2x">
        <HighRisk width="20px" height="20px" />
        <Text>
          <Trans i18nKey="riskGraph.legend.highRiskLevel" />
        </Text>
      </Flex>
      <Flex gap="2x">
        <MediumRisk width="20px" height="20px" />
        <Text>
          <Trans i18nKey="riskGraph.legend.mediumRiskLevel" />
        </Text>
      </Flex>
      <Flex gap="2x">
        <RegionLine width="20px" height="20px" />
        <Text>
          <Trans i18nKey="riskGraph.legend.regionBoundaries" />
        </Text>
      </Flex>
      <Flex gap="2x">
        <GroupLine width="20px" height="20px" />
        <Text>
          <Trans i18nKey="riskGraph.legend.groupBoundaries" />
        </Text>
      </Flex>
      <>
        <Flex gap="2x">
          <Connection width="20px" height="20px" />
          <Text>
            <Trans i18nKey="riskGraph.legend.connection" />
          </Text>
        </Flex>
        <Flex gap="2x">
          <RiskyConnection width="20px" height="20px" />
          <Text>
            <Trans i18nKey="riskGraph.legend.riskyConnection" />
          </Text>
        </Flex>
      </>
      <Flex gap="2x">
        <ActiveConnection width="20px" height="20px" />
        <Text>
          <Trans i18nKey="riskGraph.legend.activeConnection" />
        </Text>
      </Flex>
    </Stack>
  );
};

export const CloudAssetsGraphLegend = () => {
  return (
    <Tooltip
      label={<LegendTooltipContent />}
      arrow={false}
      placement="left-start"
      backgroundColor="gray:80"
      color="white:primary"
      whiteSpace="nowrap"
    >
      <Flex width="8x" height="8x" alignItems="center" justifyContent="center">
        <Icon
          tabIndex={0}
          width="4x"
          height="4x"
          as={InfoOIcon}
          color="white:emphasis"
          backgroundColor={graphBGColorCode}
          data-id="legend-info-icon"
        />
      </Flex>
    </Tooltip>
  );
};

CloudAssetsGraphLegend.displayName = 'CloudAssetsGraphLegend';
