import {
  TextLabel,
  Flex,
  Switch as TonicSwitch,
  CheckboxProps as TonicSwitchProps,
} from '@tonic-ui/react';

interface SwitchProps extends TonicSwitchProps {
  label: string;
  id: string;
}

const Switch = (props: SwitchProps) => {
  const { label, id, ...rest } = props;
  return (
    <Flex gap="3x" alignItems="center">
      <TextLabel color="white:emphasis" htmlFor={id} cursor="pointer" userSelect="none">
        {label}
      </TextLabel>

      <TonicSwitch id={id} {...rest} />
    </Flex>
  );
};

Switch.displayName = 'Switch';
export default Switch;
