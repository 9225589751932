import { ReactNode, useState, Dispatch, useEffect, useCallback } from 'react';
import { Table, TableHeader, TableRow, TableCell, TableBody, useColorMode } from '@tonic-ui/react';

type SimpleTableProps<TData extends object> = {
  data: Array<TData>;
  columns: Array<SimpleTableColumnDef<TData>>;
  onSelectRow: Dispatch<TData | undefined>;
  id: string;
  defaultRowSelection?: TData;
};

export interface SimpleTableColumnDef<TData extends object, TKey = keyof TData> {
  header: ReactNode;
  accessorKey: TKey;
  size: number;
  cell?: (cellProps: { value: TData[keyof TData] }) => ReactNode;
}

const SimpleTable = <TData extends object>({
  data,
  columns,
  onSelectRow,
  id,
  defaultRowSelection,
}: SimpleTableProps<TData>) => {
  const [colorMode] = useColorMode();
  const [currentRow, setCurrentRow] = useState<TData>();

  const hoverBackgroundColor = {
    dark: 'rgba(255, 255, 255, 0.12)',
    light: 'rgba(0, 0, 0, 0.12)',
  }[colorMode];
  const selectedBackgroundColor = {
    dark: 'rgba(255, 255, 255, 0.08)',
    light: 'rgba(0, 0, 0, 0.08)',
  }[colorMode];

  const handleRowSelection = useCallback(
    (row: TData) => {
      onSelectRow(row);
      setCurrentRow(row);
    },
    [onSelectRow, setCurrentRow]
  );

  useEffect(() => {
    // Auto-select first row if no row is selected
    if (data?.length > 0) {
      // Need to verify if a provider was selected first.
      if (defaultRowSelection) {
        handleRowSelection(defaultRowSelection);
      } else {
        handleRowSelection(data[0]);
      }
    }
  }, [data, handleRowSelection, defaultRowSelection]);

  return (
    <Table size="sm" data-id={id}>
      <TableHeader>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell key={index} width={`${column.size}px`}>
              {column.header}
            </TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => {
          return (
            <TableRow
              key={index}
              _hover={{ backgroundColor: hoverBackgroundColor }}
              style={currentRow === row ? { backgroundColor: selectedBackgroundColor } : {}}
              onClick={() => handleRowSelection(row)}
            >
              {columns.map((column, iCol) => (
                <TableCell key={iCol} width={column.size}>
                  {column.cell
                    ? column.cell({ value: row[column.accessorKey] })
                    : (row[column.accessorKey] as ReactNode)}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

SimpleTable.displayName = 'SimpleTable';
export default SimpleTable;
