import { useState, useEffect, Dispatch, SetStateAction } from 'react';

const getStorageValue = <T,>(key: string, defaultValue: T) => {
  const item = localStorage.getItem(key);
  return (item !== null ? JSON.parse(item) : defaultValue) as T;
};

export const useLocalStorage = <S,>(
  key: string,
  defaultValue: S | (() => S),
  skipSettingValue?: boolean
): [S, Dispatch<SetStateAction<S>>] => {
  const [value, setValue] = useState<S>(() =>
    getStorageValue(key, defaultValue instanceof Function ? defaultValue() : defaultValue)
  );
  useEffect(() => {
    if (!skipSettingValue) localStorage.setItem(key, JSON.stringify(value));
  }, [key, value, skipSettingValue]);
  return [value, setValue];
};
