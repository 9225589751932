import { ComponentType, useState, useEffect, Dispatch } from 'react';
import { SearchInputProps, SearchInput } from '@tonic-ui/react';
import { pendoTrack } from '../../utils/pendo';

interface GlobalFilterProps {
  setGlobalFilter: Dispatch<string>;
  SearchComponent?: ComponentType<SearchInputProps>;
  placeholder?: string;
  autoFocus?: boolean;
}

const GlobalFilter = (props: GlobalFilterProps) => {
  const { setGlobalFilter, SearchComponent = SearchInput, placeholder, autoFocus = false } = props;
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setGlobalFilter(searchValue);
      pendoTrack('REX_searchFilter_activated', {});
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [searchValue, setGlobalFilter]);

  return (
    <SearchComponent
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value.trim())}
      onClearInput={() => {
        setSearchValue('');
        // Update global filter immediately, so we don't wait for debounce
        setGlobalFilter('');
        pendoTrack('REX_searchFilter_cleared');
      }}
      placeholder={placeholder}
      autoFocus={autoFocus}
    />
  );
};

GlobalFilter.displayName = 'GlobalFilter';
export default GlobalFilter;
