const RISK_LEVEL_LABELs = {
  Low: 'enum.riskLevelOptions.low',
  Medium: 'enum.riskLevelOptions.medium',
  High: 'enum.riskLevelOptions.high',
};

const LEVEL_MAP: Record<string, string> = {
  low: RISK_LEVEL_LABELs.Low,
  medium: RISK_LEVEL_LABELs.Medium,
  high: RISK_LEVEL_LABELs.High,
};

export { LEVEL_MAP };
