import type { ColumnDef, RowData } from '@tanstack/react-table';
import {
  Row,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  ColumnSort,
} from '@tanstack/react-table';
import {
  Flex,
  Icon,
  Table as TonicTable,
  TableHeader as TonicTableHeader,
  TableRow as TonicTableRow,
  TableCell as TonicTableCell,
  TableBody as TonicTableBody,
  useColorMode,
  TableScrollbar as TonicTableScrollbar,
  Stack,
  Box,
  SearchInputProps,
  TonicProps,
} from '@tonic-ui/react';
import { SortDownIcon, SortUpIcon } from '@tonic-ui/react-icons';
import { useTranslation } from 'react-i18next';
import {
  useEffect,
  useState,
  Dispatch,
  useCallback,
  ComponentType,
  ReactNode,
  useRef,
} from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import GlobalFilter from './GlobalFilter';
import TruncateWithTooltip from '../TruncateWithTooltip';

interface TableProps<TData extends RowData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  onSelectRow?: Dispatch<TData | undefined>;
  showSearch?: boolean;
  // Replacement component for the default SearchInput. Must also set showSearch to true
  SearchComponent?: ComponentType<SearchInputProps>;
  emptySearchDisplay?: ReactNode;
  id: string;
  selectedRow: TData | undefined;
  defaultRowSelection?: TData;
}

const Table = <TData extends RowData>(props: TableProps<TData>) => {
  const {
    data,
    columns,
    onSelectRow,
    showSearch = false,
    SearchComponent,
    emptySearchDisplay,
    selectedRow,
    id,
    defaultRowSelection,
  } = props;
  const { t } = useTranslation();
  const [colorMode] = useColorMode();
  const hoverBackgroundColor = {
    dark: 'rgba(255, 255, 255, 0.12)',
    light: 'rgba(0, 0, 0, 0.12)',
  }[colorMode];
  const selectedBackgroundColor = {
    dark: 'rgba(255, 255, 255, 0.08)',
    light: 'rgba(0, 0, 0, 0.08)',
  }[colorMode];

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<ColumnSort[]>(
    'accessorKey' in columns[2] ? [{ id: 'highestScore', desc: true }] : []
  );
  const [tableWidth, setTableWidth] = useState(0);
  const columnMinSize = 50;
  const [currentRow, setCurrentRow] = useState<Row<TData> | null>(null);
  // Keep track of the last scroll position to restore it when the table is re-created
  const lastScrollTopRef = useRef(0);

  const handleRowClick = useCallback(
    (row: Row<TData>) => {
      setCurrentRow(row);
      if (onSelectRow) {
        onSelectRow(row.original);
      }
    },
    [onSelectRow]
  );

  const handleRowKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const selectedRow = document.activeElement;
    if (e.key === 'ArrowDown') {
      (selectedRow?.nextSibling as HTMLDivElement).focus();
    } else if (e.key === 'ArrowUp') {
      (selectedRow?.previousSibling as HTMLDivElement).focus();
    }
  };

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      minSize: columnMinSize,
    },
    state: {
      sorting,
      globalFilter,
    },
    enableSorting: true,
    enableMultiSort: false,
    enableSortingRemoval: false,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const scrollCurrentRowIntoView = () => {
    // when filter or sorting changes, try to move the currently selected row into view
    if (!currentRow) return;

    const selectedRow = document.getElementById(currentRow.id);
    selectedRow?.scrollIntoView({ inline: 'center', behavior: 'auto' });
  };

  useEffect(() => {
    if (data.length > 0 && !currentRow) {
      const rowToSelect = defaultRowSelection
        ? table.getRowModel().rows.find((row) => row.original === defaultRowSelection)
        : table.getRowModel().rows[0];

      if (rowToSelect) {
        handleRowClick(rowToSelect);
      }
    }
  }, [data, currentRow, handleRowClick, table, defaultRowSelection]);

  useEffect(() => {
    const selectedRowModel = table.getRowModel().rows.find((row) => row.original === selectedRow);
    if (selectedRowModel) {
      handleRowClick(selectedRowModel);
    }
  }, [selectedRow, handleRowClick, table]);

  useEffect(() => {
    scrollCurrentRowIntoView();
    // Can't add scrollCurrentRowIntoView because it changes every time we select a row
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, globalFilter]);

  useEffect(() => {
    if (!tableWidth) {
      return;
    }

    const gutterWidth = 12 + 12; // 12px padding on each side of the cell

    // Fixed columns are columns with a fixed size (e.g. 100 or '10%')
    const fixedColumns = table
      .getAllColumns()
      .filter((column) => column.columnDef.customSize !== 'auto')
      .map((column) => {
        const { id, columnDef } = column;
        const { customSize } = columnDef;
        let { minSize } = columnDef;
        if (minSize === undefined) {
          minSize = columnMinSize;
        }

        // If the column size is a number, return the original size value
        if (typeof customSize === 'number') {
          return {
            id,
            size: customSize,
          };
        }

        // If the column size is a percentage, return the computed size value
        if (typeof customSize === 'string' && customSize.endsWith('%')) {
          const percentageWidth = (tableWidth * parseFloat(customSize)) / 100;

          return {
            id,
            size: Math.max(
              percentageWidth, // percentage of table width
              gutterWidth, // text width with padding
              minSize // minimum size (e.g. 40px)
            ),
          };
        }

        // Otherwise, return the minimum size value
        return {
          id,
          size: minSize,
        };
      });

    // Flexible columns are columns with a flexible size (e.g. 'auto')
    const flexColumns = table
      .getAllColumns()
      .filter((column) => column.columnDef.customSize === 'auto')
      .map((column) => {
        const { id, columnDef } = column;
        let { minSize } = columnDef;
        if (minSize === undefined) {
          minSize = columnMinSize;
        }
        return {
          id,
          size: Math.max(
            gutterWidth, // text width with padding
            minSize // minimum size (e.g. 40px)
          ),
        };
      });

    const totalFixedColumnSize = fixedColumns.reduce((acc, column) => acc + column.size, 0);
    const totalFlexColumnSize = flexColumns.reduce((acc, column) => acc + column.size, 0);

    let extraSpaceLeft = tableWidth - totalFixedColumnSize;

    // Distribute extra space to fixed columns if flex columns are not present
    if (flexColumns.length === 0 && extraSpaceLeft > 0) {
      const extraSpacePerColumn = extraSpaceLeft / fixedColumns.length;
      fixedColumns.forEach((column) => {
        if (column.size !== undefined) column.size = column.size + extraSpacePerColumn;
      });
      extraSpaceLeft = 0;
    }

    // Distribute extra space to flex columns if flex columns are present
    if (flexColumns.length > 0 && extraSpaceLeft > totalFlexColumnSize) {
      flexColumns.forEach((column, index) => {
        column.size = Math.max(extraSpaceLeft / (flexColumns.length - index), column.size);
        extraSpaceLeft -= column.size;
      });
    }

    const columnSizing: { [key: string]: number } = {};

    for (let i = 0; i < fixedColumns.length; i++) {
      const column = fixedColumns[i];
      if (column.size !== undefined) columnSizing[column.id] = column.size;
    }
    for (let i = 0; i < flexColumns.length; i++) {
      const column = flexColumns[i];
      columnSizing[column.id] = column.size;
    }

    table.setColumnSizing(columnSizing);
  }, [columns, table, tableWidth]);

  const renderSortIcon = (isSorted: boolean | 'asc' | 'desc') => {
    if (isSorted === 'asc') {
      return <Icon as={SortUpIcon} size="5x" marginLeft="1x" />;
    } else if (isSorted === 'desc') {
      return <Icon as={SortDownIcon} size="5x" marginLeft="1x" />;
    } else {
      return null;
    }
  };

  return (
    <Stack height="100%">
      <Box>
        {showSearch && (
          <GlobalFilter
            setGlobalFilter={setGlobalFilter}
            SearchComponent={SearchComponent}
            placeholder={t('searchAccountsOrRegion')}
          />
        )}
      </Box>
      <Box flex="1">
        <AutoSizer
          onResize={({ width }) => {
            if (tableWidth !== width) {
              setTableWidth(width);
            }
          }}
        >
          {({ width, height }: { width: number; height: number }) => {
            const tableData = table.getRowModel().rows;
            return (
              <TonicTable layout="flexbox" width={width} height={height} size={'lg'} data-id={id}>
                <TonicTableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TonicTableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        const headerCellStyle = {
                          minWidth: header.column.columnDef.minSize,
                          width: header.getSize(),
                          cursor: header.column.getToggleSortingHandler() ? 'pointer' : 'default',
                        };
                        return (
                          <TonicTableCell
                            tabIndex={0}
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            onKeyDown={(e) => {
                              if (e?.key === 'Enter') {
                                e.stopPropagation();
                                header.column.toggleSorting();
                              }
                            }}
                            style={headerCellStyle}
                          >
                            {header.isPlaceholder ? null : (
                              <Flex alignItems="center">
                                {typeof header.column.columnDef.header === 'string' ? (
                                  <TruncateWithTooltip
                                    text={
                                      flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )?.toString() ?? ''
                                    }
                                    tooltipStyles={{
                                      PopperProps: { usePortal: true },
                                      placement: 'top',
                                    }}
                                  />
                                ) : (
                                  flexRender(header.column.columnDef.header, header.getContext())
                                )}

                                {renderSortIcon(header.column.getIsSorted())}
                              </Flex>
                            )}
                          </TonicTableCell>
                        );
                      })}
                    </TonicTableRow>
                  ))}
                </TonicTableHeader>
                {showSearch && globalFilter && tableData.length === 0 ? (
                  emptySearchDisplay
                ) : (
                  <TonicTableScrollbar
                    scrollTop={lastScrollTopRef.current}
                    height="100%"
                    overflow="visible"
                    onUpdate={(values) => {
                      lastScrollTopRef.current = values.scrollTop;
                    }}
                  >
                    <TonicTableBody>
                      {tableData.map((row) => (
                        <TonicTableRow
                          tabIndex={0}
                          key={row.id}
                          id={row.id}
                          style={
                            currentRow?.id === row.id
                              ? { backgroundColor: selectedBackgroundColor }
                              : {}
                          }
                          onClick={() => handleRowClick(row)}
                          onKeyDown={(e) => {
                            if (e?.key === 'Enter') {
                              e.stopPropagation();
                              handleRowClick(row);
                            } else {
                              handleRowKeyDown(e);
                            }
                          }}
                          _hover={{ backgroundColor: hoverBackgroundColor }}
                          _selected={{ backgroundColor: selectedBackgroundColor }}
                        >
                          {row.getVisibleCells().map((cell) => {
                            const cellStyles: TonicProps = {
                              overflowX: 'hidden',
                              wordBreak: 'break-word',
                            };
                            return (
                              <TonicTableCell
                                key={cell.id}
                                {...cellStyles}
                                style={{
                                  minWidth: cell.column.columnDef.minSize,
                                  width: cell.column.getSize(),
                                }}
                              >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TonicTableCell>
                            );
                          })}
                        </TonicTableRow>
                      ))}
                    </TonicTableBody>
                  </TonicTableScrollbar>
                )}
              </TonicTable>
            );
          }}
        </AutoSizer>
      </Box>
    </Stack>
  );
};

Table.displayName = 'Table';
export default Table;
