import {
  ContainerCluster,
  NetworkConfiguration,
  DataStorage,
  Database,
  IAM,
  Serverless,
  VirtualMachine,
  Subnet,
  API,
  ApplicationComponent,
  Configuration,
  Secret,
  Other,
} from '../../../../assets/Icons/assetGraph';

export type assetGraphIconsProps = {
  category: string | undefined;
  width: string;
  height: string;
};

const AssetGraphIcons = (props: assetGraphIconsProps) => {
  const { category, ...otherProps } = props;

  switch (category) {
    case 'Container Cluster':
      return <ContainerCluster {...otherProps} />;
    case 'Network Configuration':
      return <NetworkConfiguration {...otherProps} />;
    case 'Data Storage':
      return <DataStorage {...otherProps} />;
    case 'Database':
      return <Database {...otherProps} />;
    case 'Identity':
      return <IAM {...otherProps} />;
    case 'Serverless':
      return <Serverless {...otherProps} />;
    case 'Virtual Machine':
      return <VirtualMachine {...otherProps} />;
    case 'Subnet':
      return <Subnet {...otherProps} />;
    case 'API':
      return <API {...otherProps} />;
    case 'Application Component':
      return <ApplicationComponent {...otherProps} />;
    case 'Configuration':
      return <Configuration {...otherProps} />;
    case 'Secret':
      return <Secret {...otherProps} />;
    case 'Other':
      return <Other {...otherProps} />;

    default:
      return <Other {...otherProps} />;
  }
};

export default AssetGraphIcons;
