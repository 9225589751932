import { Box } from '@tonic-ui/react';
import { useEffect, useRef, useContext } from 'react';
import CloudAssetsGraphProvider from './CloudAssetsGraphProvider';
import CloudAssetsGraph from './CloudAssetsGraph';
import GraphLoaderOverlay from './GraphOverlays/CloudAssetsGraphLoaderOverlay';
import GraphEmptyOverlay from './GraphOverlays/CloudAssetsGraphEmptyOverlay';
import GraphApiErrorOverlay from '../../components/error/GraphApiErrorOverlay';
import { API } from '../../services';
import { AppContext } from '../../store/store';

export type CloudAssetsGraphContainerProps = {
  activeGraphType: SvcRisksApi.Parameters.GraphType;
  setRiskViewSwitchIsDisabled: (riskViewIsDisabled: boolean) => void;
};

const CloudAssetsGraphContainer = (props: CloudAssetsGraphContainerProps) => {
  const { activeGraphType, setRiskViewSwitchIsDisabled } = props;

  const [{ selectedAccount }] = useContext(AppContext);

  const { isLoading, data, isError, error } = API.useGetListAssetGroups({
    provider: selectedAccount?.accountProvider,
    accountID: selectedAccount?.accountID,
    region: selectedAccount?.accountRegion,
    graphType: activeGraphType,
  });

  const flipGraphOrientationRef = useRef<boolean>(false);
  const activeGraphTypeRef = useRef<'riskView' | 'networkView' | undefined>(undefined);
  const selectedAccountIDRef = useRef<string | undefined>(undefined);
  const selectedAccountRegionRef = useRef<string | undefined>(undefined);
  const grid = data?.data?.grids.find(
    (grid) => grid.id === (activeGraphType === 'riskView' ? 'RiskFocusGrouping' : 'Subnet')
  );

  if (
    !(
      activeGraphTypeRef.current !== activeGraphType &&
      selectedAccountIDRef.current === selectedAccount?.accountID &&
      selectedAccountRegionRef.current === selectedAccount?.accountRegion
    )
  ) {
    if (grid) flipGraphOrientationRef.current = !!grid.height && grid.height > grid.width;
  }

  activeGraphTypeRef.current = activeGraphType;
  selectedAccountIDRef.current = selectedAccount?.accountID;
  selectedAccountRegionRef.current = selectedAccount?.accountRegion;

  useEffect(() => {
    if (!isLoading) setRiskViewSwitchIsDisabled(false);
  }, [data, isLoading, setRiskViewSwitchIsDisabled]);

  const graphType = data?.headers['graph-type'] || 'networkView';

  return (
    <Box alignSelf="center" width="100%" height="100%" zIndex="1" data-id="graph-container">
      {!selectedAccount?.accountProvider ||
      !selectedAccount?.accountID ||
      !selectedAccount?.accountRegion ? (
        <GraphEmptyOverlay emptyOverlayType="noAccountSelected" />
      ) : isLoading ? (
        <GraphLoaderOverlay />
      ) : selectedAccount?.accountID && data?.data ? (
        <CloudAssetsGraphProvider>
          <CloudAssetsGraph
            data={data.data}
            dataType={graphType}
            flipGraphOrientation={flipGraphOrientationRef.current}
          />
        </CloudAssetsGraphProvider>
      ) : isError && error.status === 404 && activeGraphType !== 'riskView' ? (
        <GraphEmptyOverlay emptyOverlayType="accountHasNoProcessedData" />
      ) : isError && error.status === 404 && activeGraphType === 'riskView' ? (
        <GraphEmptyOverlay emptyOverlayType="accountHasNoProcessedDataForRiskView" />
      ) : !isError && data.status === 204 ? (
        <GraphEmptyOverlay emptyOverlayType="accountHasNoContent" />
      ) : (
        <GraphApiErrorOverlay error={error} />
      )}
    </Box>
  );
};

CloudAssetsGraphContainer.displayName = 'CloudAssetsGraphContainer';
export default CloudAssetsGraphContainer;
