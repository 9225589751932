import { Stack, ButtonGroup, Button, Icon, Tooltip, useTheme } from '@tonic-ui/react';
import { AddIcon, MinusIcon, TargetIcon } from '@tonic-ui/react-icons';
import { Trans } from 'react-i18next';
import { graphBGColorCode } from '../helpers';

type CloudAssetsGraphZoomButtonsProps = {
  defaultZoom: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
  graphContentIsOutOfBoundaries: boolean;
};

const CloudAssetsGraphZoomButtons = (props: CloudAssetsGraphZoomButtonsProps) => {
  const { defaultZoom, zoomIn, zoomOut, graphContentIsOutOfBoundaries } = props;

  const theme = useTheme();
  const { colors } = theme;

  return (
    <Stack>
      <ButtonGroup variant="secondary" orientation="vertical">
        <Tooltip
          isOpen={graphContentIsOutOfBoundaries}
          placement="left"
          label={<Trans i18nKey="riskGraph.zoomControls.graphIsOutOfBoundaries" />}
          PopperProps={{ usePortal: true }}
          background="gray:80"
          color={colors['white:secondary']}
          whiteSpace="pre-wrap"
        >
          <Button
            data-id="reset-zoom-button"
            data-track="REX_graphZoomButtons_resetZoom"
            width="8x"
            height="8x"
            backgroundColor={graphContentIsOutOfBoundaries ? 'blue:50' : graphBGColorCode}
            variant={graphContentIsOutOfBoundaries ? 'primary' : 'secondary'}
            onClick={defaultZoom}
          >
            <Icon as={TargetIcon} />
          </Button>
        </Tooltip>
        <Button
          data-id="zoom-in-button"
          data-track="REX_graphZoomButtons_zoomIn"
          width="8x"
          height="8x"
          backgroundColor={graphBGColorCode}
          onClick={zoomIn}
        >
          <Icon as={AddIcon} />
        </Button>
        <Button
          data-id="zoom-out-button"
          data-track="REX_graphZoomButtons_zoomOut"
          width="8x"
          height="8x"
          backgroundColor={graphBGColorCode}
          onClick={zoomOut}
        >
          <Icon as={MinusIcon} />
        </Button>
      </ButtonGroup>
    </Stack>
  );
};

CloudAssetsGraphZoomButtons.displayName = 'CloudAssetsGraphZoomButtons';
export default CloudAssetsGraphZoomButtons;
