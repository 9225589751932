import { t } from 'i18next';
import { getUICTimezoneOffset } from './uic';

export const formatTime = (
  timestamp: string | number | Date | undefined,
  format?: string,
  isUTC = false
) => {
  if (typeof timestamp !== 'number') {
    return '';
  }
  const length = timestamp.toString().length;
  if (length === 10) {
    timestamp *= 1000;
  }
  if (isUTC) {
    return toISOString(new Date(timestamp), format);
  }
  return toTimezoneString(timestamp, format);
};

const pad = (num: number) => {
  if (num < 10) {
    return '0' + num;
  }
  return num;
};

export const FORMATs = {
  MM_DD: 'MM-DD',
  YYYY_MM_DD: 'YYYY-MM-DD',
  MM_DD_hh_mm: 'MM-DD hh:mm',
  YYYY_MM_DD_hh_mm: 'YYYY-MM-DD hh:mm',
  YYYY_MM_DD_hh_mm_ss: 'YYYY-MM-DD hh:mm:ss',
  Mon_DD_YYYY: 'MMM D, YYYY',
  Month_DD_YYYY: 'MMMM D, YYYY',
  Mon_DD: 'MMM D',
};

const toISOString = (date: Date, format: any, showFlag = true) => {
  const Y = date.getUTCFullYear();
  const M = pad(date.getUTCMonth() + 1);
  const D = pad(date.getUTCDate());
  const h = pad(date.getUTCHours());
  const m = pad(date.getUTCMinutes());
  const s = pad(date.getUTCSeconds());
  if (format === FORMATs.MM_DD) {
    return `${M}-${D}`;
  } else if (format === FORMATs.YYYY_MM_DD) {
    return `${Y}-${M}-${D}`;
  } else if (format === FORMATs.MM_DD_hh_mm) {
    return `${M}-${D} ${h}:${m}`;
  } else if (format === FORMATs.YYYY_MM_DD_hh_mm) {
    return `${Y}-${M}-${D} ${h}:${m}`;
  } else if (format === FORMATs.YYYY_MM_DD_hh_mm_ss) {
    return `${Y}-${M}-${D} ${h}:${m}:${s}`;
  } else if (format === FORMATs.Mon_DD_YYYY) {
    return t(`enums.monthDateYear.${date.getUTCMonth()}`, { date: D, year: Y });
  } else if (format === FORMATs.Month_DD_YYYY) {
    return t(`enums.fullMonthDateYear.${date.getUTCMonth()}`, { date: D, year: Y });
  } else if (format === FORMATs.Mon_DD) {
    return t(`enums.monthDate.${date.getUTCMonth()}`, { date: D });
  }
  return `${Y}-${M}-${D}${showFlag ? 'T' : ' '}${h}:${m}:${s}${showFlag ? 'Z' : ''}`;
};

const toTimezoneString = (timestamp: number, format?: string) => {
  const offset = getUICTimezoneOffset({ timestamp });
  const timezoneTimestamp = timestamp - offset * 60000;
  return toISOString(new Date(timezoneTimestamp), format, false);
};
